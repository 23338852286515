import React, { useEffect } from 'react';
import CustomButton from '../../components/CustomButton/CustomButton';
import './HomePage.styles.scss';
import HomePageIcons from '../../components/HomePageIcons/HomePageIcons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjects,
  deleteProject,
  copyProject,
  editProject,
} from '../../redux/project/project.actions';
import OverviewCard from '../../components/OverviewCard/OverviewCard';

const HomePage = (props) => {
  const dispatch = useDispatch();
  const projects = useSelector((state) => state.project.projects);
  const { history } = props;

  useEffect(() => {
    dispatch(getProjects());
  }, [dispatch, history]);

  const editProjectHandler = (id, name) => {
    dispatch(editProject(id, name));
    props.history.push(`/building-data`);
  };

  const ComponentToDisplay = () =>
    projects.length === 0 ? (
      <div>
        You have no saved projects, click{' '}
        <span
          className="create-project-link"
          onClick={() => props.history.push(`/building-data`)}
        >
          here
        </span>{' '}
        to create a new project.
      </div>
    ) : (
      projects.map((project) => (
        <div className="overview-card-container" key={project._id}>
          <span>{project.projectName}</span>
          <OverviewCard
            image={project.newFacade.stylePackage}
            calculations={project.calculations}
            editHandler={() =>
              editProjectHandler(project._id, project.projectName)
            }
            copyHandler={() => dispatch(copyProject(project._id))}
            deleteHandler={() => dispatch(deleteProject(project._id))}
          />
        </div>
      ))
    );

  return (
    <div className="content-container">
      <div className="overview-content">
        <h2>Project Overview</h2>
        <div className="overview-cards">
          <ComponentToDisplay />
        </div>
        <HomePageIcons history={props.history} />
      </div>
      <div className="navbuttons">
        <CustomButton onClick={() => props.history.push(`/building-data`)}>
          Create Project <span className="plusicon">+</span>
        </CustomButton>
      </div>
    </div>
  );
};

export default HomePage;
