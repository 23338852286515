import { projectActionTypes } from './project.types';
import { resetBuilding, editBuilding } from '../building/building.actions';
import { resetFacade, editFacade } from '../facade/facade.actions';
import {
  resetNewFacade,
  editNewFacade,
  initiateDbNewFacade,
} from '../newFacade/newFacade.actions';
import { baseUrl } from '../../api/index';

export const saveProject = (name) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const state = getState();

    let data = {
      projectName: name,
      building: state.building,
      facade: state.facade,
      newFacade: state.newFacade,
      calculations: state.project.calculations,
    };
    if (state.project.projectId) {
      data.projectId = state.project.projectId;
    }

    const response = await fetch(`${baseUrl}/projects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.statusText;
      let message = 'Something went wrong!';
      if (errorId === 'EMAIL_EXISTS') {
        message = 'This email exists already!';
      }
      throw new Error(message);
    }
    dispatch({
      type: projectActionTypes.SAVE_PROJECT,
      name: name,
    });
    dispatch(getProjects());
    dispatch(resetState());
  };
};

export const getProjects = () => {
  return async (dispatch, getState) => {
    const token = await getState().auth.token;

    try {
      if (token !== '') {
        const response = await fetch(`${baseUrl}/projects/me`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: token,
          },
        });
        const resData = await response.json();
        dispatch({
          type: projectActionTypes.GET_PROJECTS,
          data: resData,
        });
      }
    } catch (err) {
      throw new Error('Something went wrong!');
    }
  };
};

export const deleteProject = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/projects/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.statusText;
      let message = 'Something went wrong!';
      if (errorId === 'EMAIL_EXISTS') {
        message = 'This email exists already!';
      }
      throw new Error(message);
    }
    dispatch(getProjects());
  };
};

export const copyProject = (id) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/projects/copy/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.statusText;
      let message = 'Something went wrong!';
      if (errorId === 'EMAIL_EXISTS') {
        message = 'This email exists already!';
      }
      throw new Error(message);
    }
    dispatch(getProjects());
  };
};

export const editProject = (id, name) => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/projects/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.statusText;
      let message = 'Something went wrong!';
      if (errorId === 'EMAIL_EXISTS') {
        message = 'This email exists already!';
      }
      throw new Error(message);
    }

    const resData = await response.json();

    dispatch(initiateDbNewFacade(resData.newFacade.stylePackage, true)); //load new cassette panels (svgs)
    dispatch(editBuilding(resData.building));
    dispatch(editFacade(resData.facade));
    dispatch(editNewFacade(resData.newFacade));
    dispatch(getEditProjectId(id, name));
  };
};

export const fetchCalculations = () => {
  return async (dispatch, getState) => {
    dispatch(projectFetchStart());

    const token = getState().auth.token;
    const state = getState();
    const data = {
      projectName: '',
      building: state.building,
      facade: state.facade,
      newFacade: state.newFacade,
      calculations: state.project.calculations,
    };
    const response = await fetch(`${baseUrl}/projects/calculations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      let message = 'Something went wrong!';
      throw new Error(message);
    }

    const resData = await response.json();
    dispatch({
      type: projectActionTypes.FETCH_CALCULATIONS,
      data: resData.calculations,
    });
    dispatch(projectFetchSuccess());
  };
};

//used to revert state to inititl state when project is being saved or when project is being interupted by hitting 'X' button top, right
export const resetState = () => {
  return async (dispatch) => {
    dispatch(resetBuilding());
    dispatch(resetFacade());
    dispatch(resetNewFacade());
    dispatch({ type: projectActionTypes.RESET_PROJECT });
  };
};

//get edited project id fro using it in request body
export const getEditProjectId = (id, name) => ({
  type: projectActionTypes.EDIT_PROJECT_ID,
  id: id,
  name: name,
});

//used to pause actions until api call gets resolved
export const projectFetchStart = () => ({
  type: projectActionTypes.PROJECT_FETCH_START,
});
export const projectFetchSuccess = () => ({
  type: projectActionTypes.PROJECT_FETCH_SUCCESS,
});
