import React from 'react';
import './PositionSelector.styles.scss';
import FrontCourtyard from '../../iconComponents/FrontCourtyard/FrontCourtyard';
import FrontFreestanding from '../../iconComponents/FrontFreestanding/FrontFreestanding';
import FrontInrows from '../../iconComponents/FrontInrows/FrontInrows';
import RearCourtyard from '../../iconComponents/RearCourtyard/RearCourtyard';
import RearFreestanding from '../../iconComponents/RearFreestanding/RearFreestanding';
import RearInrows from '../../iconComponents/RearInrows/RearInrows';
import { useSelector, useDispatch } from 'react-redux';
import { resetDaylightMarkers } from '../../redux/newFacade/newFacade.actions';
import { setPosition } from '../../redux/facade/facade.actions';

const PositionSelector = (props) => {
  const { editedFacade } = props;
  const position = useSelector((state) => state.facade[editedFacade].position);
  const stylePackage = useSelector((state) => state.newFacade.stylePackage);
  const dispatch = useDispatch();

  const positionHandler = (position) => {
    dispatch(setPosition(position));
    //used to reset newfacade, to remove daylight markers
    if (stylePackage !== '') {
      dispatch(resetDaylightMarkers());
    }
  };

  return (
    <div className="position-selector">
      <div className="Label">Neighbourhood position: </div>
      {editedFacade === 'front' && (
        <div className="position-icons-container">
          <div
            className={
              position === 'Free standing'
                ? 'position-icon-active'
                : 'position-icon'
            }
            onClick={() => positionHandler('Free standing')}
          >
            <FrontFreestanding />
            Free standing
          </div>
          <div
            className={
              position === 'Courtyard'
                ? 'position-icon-active'
                : 'position-icon'
            }
            onClick={() => positionHandler('Courtyard')}
          >
            <FrontCourtyard />
            Courtyard
          </div>

          <div
            className={
              position === 'In rows' ? 'position-icon-active' : 'position-icon'
            }
            onClick={() => positionHandler('In rows')}
          >
            <FrontInrows />
            In rows
          </div>
        </div>
      )}
      {editedFacade === 'rear' && (
        <div className="position-icons-container">
          <div
            className={
              position === 'Free standing'
                ? 'position-icon-active'
                : 'position-icon'
            }
            onClick={() => positionHandler('Free standing')}
          >
            <RearFreestanding />
            Free standing
          </div>
          <div
            className={
              position === 'Courtyard'
                ? 'position-icon-active'
                : 'position-icon'
            }
            onClick={() => positionHandler('Courtyard')}
          >
            <RearCourtyard />
            Courtyard
          </div>

          <div
            className={
              position === 'In rows' ? 'position-icon-active' : 'position-icon'
            }
            onClick={() => positionHandler('In rows')}
          >
            <RearInrows />
            In rows
          </div>
        </div>
      )}
    </div>
  );
};
export default PositionSelector;
