export const buildingActionTypes = {
  SET_BUILDING_NAME: 'SET_BUILDING_NAME',
  SET_BUILDING_LOCATION: 'SET_BUILDING_LOCATION',
  INCRAMENT: 'INCRAMENT',
  DECRAMENT: 'DECRAMENT',
  SELECT_PANELS_NUM: 'SELECT_PANELS_NUM',
  SET_YEAR: 'SET_YEAR',
  SET_ORIENTATION: 'SET_ORIENTATION',
  CHECK_ITEM: 'CHECK_ITEM',
  UNCHECK_ITEM: 'UNCHECK_ITEM',
  EDIT_BUILDING: 'EDIT_BUILDING', //used when project is being edited
  RESET_BUILDING: 'RESET_BUILDING', //uset to clear state to initial state
  RESET_INSULATION: 'RESET_INSULATION', //resets insulation to 'None', other changes triggred by SELECT_PANELS_NUM
};
