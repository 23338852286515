import { newFacadeActionTypes } from './newFacade.types';
import { setIsLoadingOptions } from '../ui/ui.actions';
import { saveNewCassettesToState } from '../auth/auth.actions';
import { baseUrl } from '../../api/index';

//retrieves cassetes and opening for particular desing option
export const fetchDesignOption = (front, rear, gable, option) => ({
  type: newFacadeActionTypes.FETCH_DESIGN_OPTION,
  front: front,
  rear: rear,
  gable: gable,
  option: option,
});

//redux-thunk used in order to access getState so we can use state from other slices (facade slice)
export const initiateDbNewFacade = (option, onlyPanels = false) => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingOptions());

    const token = getState().auth.token;
    const facade = getState().facade;
    const stairway = getState().building.stairway;
    const data = { option: option, facade: facade, hasStairway: stairway };
    const response = await fetch(`${baseUrl}/cassettes/option`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.statusText;
      let message = 'Something went wrong!';
      if (errorId === 'EMAIL_EXISTS') {
        message = 'This email exists already!';
      }
      throw new Error(message);
    }

    const resData = await response.json();
    if (onlyPanels === false) {
      dispatch(
        fetchDesignOption(
          resData.newFacade.front,
          resData.newFacade.rear,
          resData.newFacade.gable,
          option
        )
      );
    }
    dispatch(saveNewCassettesToState(resData));
    dispatch(setIsLoadingOptions());
  };
};

export const updateNewPanels = (
  id,
  unitid,
  name,
  openingOne,
  openingTwo,
  hasSunscreen = false,
  hasBalcony = false
) => (dispatch, getState) => {
  const beingEdited = getState().facade.beingEdited;
  dispatch({
    type: newFacadeActionTypes.UPDATE_NEW_PANELS,
    id: id,
    //unitid perhaps will be redundant if we do not use individual panel update
    unitid: unitid,
    name: name,
    openingOne: openingOne,
    openingTwo: openingTwo,
    beingEdited: beingEdited,
    hasSunscreen: hasSunscreen,
    hasBalcony: hasBalcony,
  });
  //only fetch daylight calculations for front and rear, ignore gable facade
  if (beingEdited !== 'gable') {
    dispatch(
      getPanelDaylight(
        id,
        name,
        openingOne,
        openingTwo,
        hasBalcony,
        beingEdited
      )
    );
  }
};

//used when editing project from existing geometry page, if existing panel width changes, this will change new as well
export const updateNewPanelWidth = (id, name, entrance = false) => (
  dispatch,
  getState
) => {
  const beingEdited = getState().facade.beingEdited;
  const stylePackage = getState().newFacade.stylePackage;
  //only made when entrance position is being changed, defined by integer (as a order number of panels ina row)
  if (entrance === true && stylePackage !== '') {
    //used to reset front facade when door position is being changed within existing facade
    //ide sent to determine if entrance is being moved from existing position
    dispatch(resetFrontFacade(id));
  }

  dispatch({
    type: newFacadeActionTypes.UPDATE_NEW_PANEL_WIDTH,
    id: id,
    name: name,
    entrance: entrance,
    beingEdited: beingEdited,
  });
};

export const editNewFacade = (data) => ({
  type: newFacadeActionTypes.EDIT_NEW_FACADE,
  data: data,
});

//used to reset front facade when door position is being changed within existing facade
export const resetFrontFacade = () => {
  return async (dispatch, getState) => {
    dispatch(setIsLoadingOptions());
    const token = getState().auth.token;
    const facade = getState().facade;
    const stairway = getState().building.stairway;
    const option = getState().newFacade.stylePackage;
    const data = { option: option, facade: facade, hasStairway: stairway };
    const response = await fetch(`${baseUrl}/cassettes/option`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      let message = 'Something went wrong!';

      throw new Error(message);
    }

    const resData = await response.json();

    //we made full call but will use only front facade
    dispatch({
      type: newFacadeActionTypes.RESET_FRONT_FACADE,
      data: resData.newFacade,
    });

    dispatch(setIsLoadingOptions());
  };
};

export const resetNewFacade = () => ({
  type: newFacadeActionTypes.RESET_NEW_FACADE,
});

//fetching panel daylight values, per panel
export const getPanelDaylight = (
  id,
  panelName,
  openingOne,
  openingTwo,
  hasBalcony,
  beingEdited
) => {
  return async (dispatch, getState) => {
    dispatch(newFacadeFetchStart());

    const token = getState().auth.token;
    const state = getState();
    const orientation = getState().building.entranceOrientation;
    const position = state.facade[beingEdited].position;
    const stylePackage = getState().newFacade.stylePackage;

    let facadeOrientation = orientation;
    if (beingEdited === 'rear') {
      switch (orientation) {
        case 'South':
          facadeOrientation = 'North';
          break;
        case 'East':
          facadeOrientation = 'West';
          break;
        case 'West':
          facadeOrientation = 'East';
          break;
        case 'North':
          facadeOrientation = 'South';
          break;
        default:
          break;
      }
    }

    const panelData = {
      id: id,
      name: panelName,
      openingOne: openingOne,
      openingTwo: openingTwo,
      hasBalcony: hasBalcony,
    };
    const data = {
      panel: panelData,
      orientation: facadeOrientation,
      position: position,
      stylePackage: stylePackage,
    };
    const response = await fetch(`${baseUrl}/projects/panel-light`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      let message = 'Something went wrong!';
      throw new Error(message);
    }
    const resData = await response.json();
    dispatch({
      type: newFacadeActionTypes.GET_PANEL_DAYLIGHT,
      id: id,
      beingEdited: beingEdited,
      data: resData,
    });
    dispatch(newFacadeFetchSuccess());
  };
};

//reseting daylight markers if orientation or position are being changed
export const resetDaylightMarkers = () => {
  return async (dispatch, getState) => {
    dispatch(newFacadeFetchStart());

    const token = getState().auth.token;
    const state = getState();
    const building = state.building;
    const newFacade = state.newFacade;
    const frontPosition = state.facade.front.position;
    const rearPosition = state.facade.rear.position;

    const data = {
      newFacade: newFacade,
      building: building,
      frontPosition: frontPosition,
      rearPosition: rearPosition,
    };
    const response = await fetch(`${baseUrl}/projects/panel-light-per-facade`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      let message = 'Something went wrong!';
      throw new Error(message);
    }
    const resData = await response.json();
    dispatch({
      type: newFacadeActionTypes.RESET_DAYLIGHT_MARKERS,
      newFacade: resData.newFacade,
    });
    dispatch(newFacadeFetchSuccess());
  };
};

//used to pause actions until api call gets resolved
export const newFacadeFetchStart = () => ({
  type: newFacadeActionTypes.NEWFACADE_FETCH_START,
});
export const newFacadeFetchSuccess = () => ({
  type: newFacadeActionTypes.NEWFACADE_FETCH_SUCCESS,
});
