import React, { useCallback, useState } from 'react';
import './FacadeNavbar.styles.scss';
import { useDispatch, useSelector } from 'react-redux';

import { beingEdited } from '../../redux/facade/facade.actions';
import { setIsLoadingOptions } from '../../redux/ui/ui.actions';

const FacadeNavbar = (props) => {
  const [activeLink, setActiveLink] = useState('front');
  const stylePackage = useSelector((state) => state.newFacade.stylePackage);

  const dispatch = useDispatch();

  const toggleFacade = useCallback(
    (text) => {
      dispatch(setIsLoadingOptions());
      setActiveLink(text);
      dispatch(beingEdited(text));
      dispatch(setIsLoadingOptions());
    },
    [dispatch]
  );

  return (
    <div className="facade-navbar-container">
      <nav>
        <button
          className={activeLink === 'front' ? 'active' : ''}
          onClick={() => toggleFacade('front')}
        >
          Front Facade
        </button>
        <button
          className={activeLink === 'rear' ? 'active' : ''}
          onClick={() => toggleFacade('rear')}
        >
          Rear Facade
        </button>
        <button
          className={activeLink === 'gable' ? 'active' : ''}
          onClick={() => toggleFacade('gable')}
        >
          Gable Facade
        </button>
        <div className={`animation start-${activeLink}`}></div>
      </nav>
      {props.new && (
        <h4>
          Choose style package:
          <span className="style-label">{stylePackage.toUpperCase()}</span>
        </h4>
      )}
    </div>
  );
};

export default FacadeNavbar;
