import axios from 'axios';

/**
 * API creator, used in actions
 */

export const api = axios.create({
  baseURL: `/api`,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
  },
});

export const baseUrl = '/api'; //'http://localhost:3001/api' or '/api'
