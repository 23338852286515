import React from 'react';
import './DaylightingResultValues.styles.scss';
import { useSelector } from 'react-redux';

const DaylightingResultValues = (props) => {
  const daylightHoverValue = useSelector(
    (state) => state.ui.daylightHoverValue
  );
  const daylightHoverValueVisibility = useSelector(
    (state) => state.ui.daylightHoverValueVisibility
  );

  const redMarkerInfo = (result) => (
    <div>
      <div className="daylight-results-value-item">
        Denne facade overholder hverken 10% reglen eller 300 lux reglen ved et
        5m dybt rum.
      </div>

      <div className="daylight-results-value-item">
        {`Hvis rummet dybere end ${result.MaxDepth}m, kan det ikke bruges som stue/køkken uden dispensation.Det vil kun være lovligt at bruge som (sove)værelse, hvis resten af lejligheden får rigeligt dagslys. Det vil i så fald kræve en detaljeret beregning af lejligheden som helhed, for at se om gennemsnittet i alle rummene er højt nok.`}
      </div>

      <div className="daylight-results-value-item">{` Omvendt hvis rummet er under ${result.MaxDepth}m dybt, vil det overholde 10% reglen og kunne bruges til alle typer opholdsrum.`}</div>

      <div>
        {' '}
        DA<sub>300,50%</sub> {`= ${result.DA300}% (Anbefalet ≥ 50)`}
      </div>
      <div>{`10% reglen = ${result.tenPercent}% (Krav ≥ 10)`}</div>
    </div>
  );

  const yellowMarkerInfo = (result) => (
    <div>
      <div className="daylight-results-value-item">
        Denne facade overholder 10% reglen ved et 5m dybt rum. Dog overholder
        den ikke 300 lux reglen, og vil derfor ikke have ”godt dagslys” som
        opholdsrum.
      </div>

      <div>
        {' '}
        DA<sub>300,50%</sub> {`= ${result.DA300}% (Anbefalet ≥ 50)`}
      </div>
      <div className="daylight-results-value-item">{`10% reglen = ${result.tenPercent}% (Krav ≥ 10)`}</div>

      <div>
        {`Rummet kunne være op til ${result.MaxDepth}m dybt og stadig overholde 10% reglen.`}
      </div>
    </div>
  );

  const greenMarkerInfo = (result) => (
    <div>
      <div className="daylight-results-value-item">
        Denne facade overhold 300 lux reglen ved et 5m dybt dum. Rummet vil have
        ”godt dagslys” som opholdsrum.
      </div>

      <div>
        {' '}
        DA<sub>300,50%</sub> {`= ${result.DA300}% (Anbefalet ≥ 50)`}
      </div>
      <div className="daylight-results-value-item">{`10% reglen = ${result.tenPercent}% (Krav ≥ 10)`}</div>

      <div>
        {`Rummet kunne være op til ${result.MaxDepth}m dybt og stadig overholde 10% reglen.`}
      </div>
    </div>
  );

  let displayValue = '';
  if (daylightHoverValue !== null && daylightHoverValue.pass === 2) {
    displayValue = greenMarkerInfo(daylightHoverValue);
  }
  if (daylightHoverValue !== null && daylightHoverValue.pass === 1) {
    displayValue = yellowMarkerInfo(daylightHoverValue);
  }
  if (daylightHoverValue !== null && daylightHoverValue.pass === 0) {
    displayValue = redMarkerInfo(daylightHoverValue);
  }

  return (
    daylightHoverValueVisibility && (
      <div className="daylight-result-values">{displayValue}</div>
    )
  );
};
export default DaylightingResultValues;
