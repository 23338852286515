import React from 'react';
import Colors from '../../constants/Colors';

const RearFreestanding = (props) => (
  <div {...props}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
    >
      <g stroke={Colors.bluemid} strokeWidth="1">
        <rect
          className="cls-1"
          x="34.55"
          y="119.83"
          width="80.9"
          height="26.64"
          fill={Colors.fill}
        />
        <rect
          className="cls-2"
          x="47.1"
          y="137.56"
          width="11.45"
          height="8.92"
          fill={Colors.main}
        />
        <rect
          className="cls-2"
          x="89.77"
          y="137.56"
          width="11.45"
          height="8.92"
          fill={Colors.main}
        />
      </g>
    </svg>
  </div>
);

export default RearFreestanding;
