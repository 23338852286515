import React, { useState, useEffect } from 'react';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import OverviewCard from '../../components/OverviewCard/OverviewCard';
import Input from '../../components/Input/Input';
import './Checkout.styles.scss';
import { useSelector, useDispatch } from 'react-redux';

import PdfDocument from '../../pdfComponents/PdfDocument';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { saveProject } from '../../redux/project/project.actions';

const Checkout = (props) => {
  const state = useSelector((state) => state);
  const [projectName, setProjectName] = useState(state.building.buildingName);
  const [stylePackage, setStylePackage] = useState('');
  const [ready, setReady] = useState(false);
  const dbCalculations = useSelector((state) => state.project.calculations);
  const newFacade = state.newFacade;
  const building = state.building;
  const projName = state.project.projectName;
  const dispatch = useDispatch();

  const actionToForward = () => {
    const pName = projectName ? projectName : 'Project';
    dispatch(saveProject(pName));
  };

  const prepareDocumentHandler = () => {
    setReady(true);
  };

  useEffect(() => {
    if (newFacade) {
      setStylePackage(newFacade.stylePackage);
    }
    if (projName) {
      setProjectName(projName);
    }
  }, [stylePackage, dispatch, newFacade, projName]);

  return (
    <div className="content-container column">
      <div className="captions">
        <h2>
          Save Project |{' '}
          {building.buildingName ? building.buildingName : ' Building Name'}
        </h2>
      </div>

      <div className="checkout-container">
        <h4 className="heading-4">Generate PDF file of the project</h4>
        <div className="checkout-container-row">
          <OverviewCard
            image={newFacade.stylePackage}
            calculations={dbCalculations}
            hover={true}
          />
          <div className="checkout-input-container">
            <Input
              type="text"
              label="Project name:"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
            {/* 3rd party package download link component */}
            {!ready ? (
              <button onClick={prepareDocumentHandler}>Generate PDF</button>
            ) : (
              <PDFDownloadLink
                document={<PdfDocument state={state} image={stylePackage} />}
                fileName={`${projectName}.pdf`}
              >
                {({ blob, url, loading, error }) => {
                  return loading ? 'Loading document...' : 'Download PDF';
                }}
              </PDFDownloadLink>
            )}
          </div>
        </div>
      </div>

      <NavigationButtons
        history={props.history}
        navigateto="/"
        next="Save Project"
        actionToForward={actionToForward}
        disabled={stylePackage === ''} //have at least style package in order to be able to save project
      />
    </div>
  );
};

export default Checkout;
