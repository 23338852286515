import React from 'react';
import Colors from '../../constants/Colors';

const RearInrows = (props) => (
  <div {...props}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
    >
      <g stroke={Colors.bluemid} strokeWidth="1">
        <polyline
          className="cls-1"
          points="-0.15 119.83 30.8 119.83 30.8 146.48 -0.15 146.48"
          fill="none"
        />
        <polyline
          className="cls-1"
          points="150.13 146.48 119.77 146.48 119.77 119.83 150.13 119.83"
          fill="none"
        />
        <rect
          className="cls-2"
          x="34.55"
          y="119.83"
          width="80.9"
          height="26.64"
          fill={Colors.fill}
        />
        <rect
          className="cls-3"
          x="47.1"
          y="137.56"
          width="11.45"
          height="8.92"
          fill={Colors.main}
        />
        <rect
          className="cls-3"
          x="89.77"
          y="137.56"
          width="11.45"
          height="8.92"
          fill={Colors.main}
        />
        <polyline
          className="cls-1"
          points="-0.15 3.52 30.8 3.52 30.8 30.16 -0.15 30.16"
          fill="none"
        />
        <polyline
          className="cls-1"
          points="150.13 30.16 119.77 30.16 119.77 3.52 150.13 3.52"
          fill="none"
        />
        <rect
          className="cls-1"
          x="34.55"
          y="3.52"
          width="80.9"
          height="26.64"
          fill="none"
        />
      </g>
    </svg>
  </div>
);

export default RearInrows;
