import React from 'react';
import './SelectPanel.styles.scss';
import PlusIcon from '../../iconComponents/PlusIcon/PlusIcon';
import Colors from '../../constants/Colors';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { showModal } from '../../redux/ui/ui.actions';

const PanelSelect = (props) => {
  const dispatch = useDispatch();
  const facades = useSelector((state) => state.facade);
  const editedFacade = facades.beingEdited;

  const selectPanelHandler = (text) => {
    dispatch(showModal());
    props.history.push(`/existing-building-geometry/${text}`);
    //this param will be used as a selector of which panels need to be changed
  };
  const beingEdited = useSelector((state) => state.facade.beingEdited);
  const units = facades[beingEdited].units;
  const floorPlanUnits = units.find((unit) => unit.id[0] === '1');

  const fields = [];
  for (let i = 1; i <= facades[editedFacade].panels; i++) {
    let labelText = `Select panel ${i}`; //default value

    if (floorPlanUnits.panels[i - 1].width !== '') {
      //when selected, assing new name
      labelText = floorPlanUnits.panels[i - 1].width + ' mm';
    }

    fields.push(
      <div
        className="panel-select-fields"
        id={i}
        key={i}
        onClick={() => selectPanelHandler(i)}
      >
        <PlusIcon fill={Colors.main} className="plusicon" /> {labelText}
      </div>
    );
  }

  return <div className="select-panel">{fields}</div>;
};

export default withRouter(PanelSelect);
