import React from 'react';

const PlusIcon = props => (
  <div {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g
        id="Group_3934"
        data-name="Group 3934"
        transform="translate(-485.5 -276.5)"
      >
        <line
          id="Line_1705"
          data-name="Line 1705"
          y2="16"
          transform="translate(493.5 276.5)"
          fill="none"
          stroke={props.fill}
          strokeWidth="1"
        />
        <line
          id="Line_1706"
          data-name="Line 1706"
          x2="16"
          transform="translate(485.5 284.5)"
          fill="none"
          stroke={props.fill}
          strokeWidth="1"
        />
      </g>
    </svg>
  </div>
);

export default PlusIcon;
