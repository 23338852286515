import { combineReducers } from 'redux';

import buildingReducer from './building/building.reducer';
import authReducer from './auth/auth.reducer';
import facadeReducer from './facade/facade.reducer';
import uiReducer from './ui/ui.reducer';
import newFacadeReducer from './newFacade/newFacade.reducer';
import projectReducer from './project/project.reducer';

const rootReducer = combineReducers({
  building: buildingReducer,
  auth: authReducer,
  facade: facadeReducer,
  newFacade: newFacadeReducer,
  ui: uiReducer,
  project: projectReducer,
});

export default rootReducer;
