import React from 'react';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import NewFacadeSelector from '../../components/NewFacadeSelector/NewFacadeSelector';
import NewFacadeConfig from '../../components/NewFacadeConfig/NewFacadeConfig';
import { useSelector, useDispatch } from 'react-redux';
import NewModalContent from '../../components/NewModalContent/NewModalContent';
import { fetchCalculations } from '../../redux/project/project.actions';

const FacadeConfig = (props) => {
  const building = useSelector((state) => state.building);
  const stylePackage = useSelector((state) => state.newFacade.stylePackage);
  const loading = useSelector((state) => state.newFacade.isLoading); //used to pause next handler until api call gets resolved
  const dispatch = useDispatch();

  const nextStepHandler = () => {
    dispatch(fetchCalculations());
  };

  return (
    <div className="content-container column">
      <div className="captions">
        <h2>New facade configuration - Performance</h2>
        <h4 className="heading-4">
          {building.buildingName ? building.buildingName : 'Building Name'}
        </h4>
      </div>
      <div className="row">
        <div className="left">
          <NewFacadeSelector />
        </div>
        <div className="right">
          <NewFacadeConfig />
        </div>
      </div>
      <NavigationButtons
        history={props.history}
        navigateto="/overview"
        actionToForward={nextStepHandler}
        disabled={stylePackage === '' || loading} //have at least style package in order to be able to save project
      />
      <NewModalContent />
    </div>
  );
};

export default FacadeConfig;
