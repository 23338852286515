import React, { useRef, useState, useEffect } from 'react';
import './PreviewComponent.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import { PanZoom } from 'react-easy-panzoom';
import { exitUnitView } from '../../redux/ui/ui.actions';
import { withRouter } from 'react-router-dom'; //will be used to reset Pan and Zoom if location.pathname changes
import DaylightingResultValues from '../DaylightingResultValues/DaylightingResultValues';

const PreviewComponent = (props) => {
  const floors = useSelector((state) => state.building.floors);
  const entrances = useSelector((state) => state.building.entrances);
  const ui = useSelector((state) => state.ui);
  const unitViewActive = ui.unitViewActive;
  const modalVisible = ui.modalVisible;
  const isLoadingOptions = ui.isLoadingOptions;
  const panRef = useRef(); //collecting ref of PanZoom component in order to extract  'reset' method
  const [resetButton, setResetButton] = useState(false);
  const dispatch = useDispatch();

  const resetPan = () => {
    if (panRef.current) {
      panRef.current.reset();
    }
    setResetButton(false);
  };

  const pathname = props.location.pathname;
  useEffect(() => {
    if (panRef.current !== null) resetPan();
    if (pathname !== '/facade') {
      resetPan();
      dispatch(exitUnitView());
    }
  }, [unitViewActive, modalVisible, isLoadingOptions, dispatch, pathname]);

  return (
    <div className="preview-component">
      <DaylightingResultValues />
      <div className="preview-header">
        <div className="axis">
          <span className="axis-span">
            <img
              src={require('../../assets/images/floors.svg')}
              alt="pin"
              className="floors-icon"
            />
            {floors}
          </span>
          stories
        </div>
        <div className="nav">
          {resetButton && !unitViewActive && (
            <div
              className="nav-item-reset"
              onClick={() => {
                resetPan();
              }}
            >
              RESET VIEW
            </div>
          )}

          {unitViewActive && (
            <div
              className="nav-item-reset"
              onClick={() => {
                dispatch(exitUnitView());
              }}
            >
              CLOSE UNIT VIEW
            </div>
          )}
          <div className={unitViewActive ? 'nav-item' : 'nav-item-bold'}>
            {!unitViewActive && <span> &bull; </span>}
            Full View
          </div>
          <div className={unitViewActive ? 'nav-item-bold' : 'nav-item'}>
            {unitViewActive && <span> &bull; </span>}
            Unit View
          </div>
        </div>
      </div>
      <div className="preview-content">
        <div className="vertical-line" />
        {unitViewActive ? (
          <div className="preview">{props.children}</div>
        ) : (
          <div className="preview">
            <PanZoom
              style={{
                cursor: 'move',
                outlineStyle: 'none',
              }}
              ref={panRef}
              onPanStart={() => setResetButton(true)}
              noStateUpdate
            >
              {props.children}
            </PanZoom>
          </div>
        )}
      </div>
      <div className="preview-footer">
        <div className="horizontal-line" />
        <div className="axis">
          <span className="axis-span">
            <img
              src={require('../../assets/images/entrances.svg')}
              alt="pin"
              className="floors-icon"
            />
            {entrances}
          </span>
          entrances
        </div>
      </div>
      <div className="preview-point" />
    </div>
  );
};
export default withRouter(PreviewComponent);
