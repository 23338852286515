import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  line: {
    margin: 'auto',
    flexDirection: 'row',
    width: '70%',
    justifyContent: 'space-between',
  },
});

const PdfBuildingData = (props) => {
  const state = props.state;

  return (
    <View style={styles.section}>
      <View style={styles.line}>
        <Text>Building location: </Text>
        <Text
          style={styles.pageNumbers}
          render={() => state.building.buildingLocation}
        />
      </View>
      <View style={styles.line}>
        <Text>Building name: </Text>
        <Text
          style={styles.pageNumbers}
          render={() => state.building.buildingName}
        />
      </View>
      <View style={styles.line}>
        <Text>Number of entrances:</Text>
        <Text
          style={styles.pageNumbers}
          render={() => ` ${state.building.entrances}`}
        />
      </View>
      <View style={styles.line}>
        <Text>Number of floors: </Text>
        <Text
          style={styles.pageNumbers}
          render={() => ` ${state.building.floors}`}
        />
      </View>
      <View style={styles.line}>
        <Text>Building accesibility: </Text>
        <Text
          style={styles.pageNumbers}
          render={() => (state.building.isAccessible ? 'Adequate' : 'Low')}
        />
      </View>
      <View style={styles.line}>
        <Text>Is building insulated: </Text>
        <Text
          style={styles.pageNumbers}
          render={() => (state.building.isInsulated ? 'Yes' : 'No')}
        />
      </View>
      <View style={styles.line}>
        <Text>Insulation thickness: </Text>
        <Text
          style={styles.pageNumbers}
          render={() =>
            state.building.isInsulated && state.building.isInsulated !== 'None'
              ? ` ${state.building.insulationThickness} mm`
              : '-'
          }
        />
      </View>
      <View style={styles.line}>
        <Text>Is building renovated: </Text>
        <Text
          style={styles.pageNumbers}
          render={() => (state.building.isRenovated ? 'Yes' : 'No')}
        />
      </View>
      <View style={styles.line}>
        <Text>Stairway type: </Text>
        <Text
          style={styles.pageNumbers}
          render={() =>
            state.building.stairway ? 'Front stairway' : 'Interior stairway'
          }
        />
      </View>
      <View style={styles.line}>
        <Text>Year of construction: </Text>
        <Text style={styles.pageNumbers} render={() => state.building.year} />
      </View>
      <View style={styles.line}>
        <Text>Entrance orientation: </Text>
        <Text
          style={styles.pageNumbers}
          render={() => state.building.entranceOrientation}
        />
      </View>
    </View>
  );
};

export default PdfBuildingData;
