import React, { useState, useEffect, useCallback } from 'react';
import './Login.styles.scss';
import Input from '../../components/Input/Input';
import CustomButton from '../../components/CustomButton/CustomButton';

import { login, authenticate, logout } from '../../redux/auth/auth.actions';

import { useDispatch } from 'react-redux';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [warning, setWarning] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const history = props.history;

  const dispatch = useDispatch();

  const loginHandler = useCallback(async () => {
    setWarning(null);
    setIsLoading(true);
    try {
      await dispatch(login(email, password));
    } catch (err) {
      setWarning('Please check your email and password');
      setIsLoading(false);
      return;
    }

    history.push(`/`);
  }, [dispatch, email, password, history]);

  useEffect(() => {
    const tryLogin = async () => {
      const userData = localStorage.getItem('userData');

      if (!userData) {
        dispatch(logout());

        return;
      }
      const transformedData = await JSON.parse(userData);
      const { userName, userId, token } = transformedData;

      dispatch(authenticate(userName, userId, token));
    };

    tryLogin();
  }, [dispatch]);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setWarning('');
  };
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  //login on enter keypress
  const onEnterPressHandler = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === 'NumpadEnter') {
        loginHandler();
      }
    },
    [loginHandler]
  );

  return (
    <div className="content-container column" onKeyPress={onEnterPressHandler}>
      <h2>Please Log In</h2>
      <div>
        <Input
          inputtype="input"
          type="email"
          name="email"
          label="Username: "
          value={email}
          onChange={(e) => onEmailChange(e)}
          warning={warning}
        />
        <Input
          inputtype="input"
          type="password"
          name="password"
          label="Password: "
          value={password}
          onChange={(e) => onPasswordChange(e)}
        />
      </div>
      <div className="warning-message">{warning}</div>
      <div className="navbuttons">
        <CustomButton onClick={loginHandler} buttonLoader={isLoading}>
          Log In
        </CustomButton>
      </div>
    </div>
  );
};

export default Login;
