import { authActionTypes } from './auth.types';

const initialState = {
  userName: '',
  userId: '',
  token: '',
  isAuthenticated: false,
  cassettes: [],
  openings: [],
  existingCassettes: [],
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.LOGIN:
      return {
        ...state,
        userName: action.userName,
        userId: action.userId,
        token: action.token,
        isAuthenticated: true,
      };
    case authActionTypes.LOGOUT:
      return {
        ...state,
        userName: '',
        userId: '',
        token: '',
        isAuthenticated: false,
      };
    case authActionTypes.AUTHENTICATE:
      return {
        userName: action.userName,
        userId: action.userId,
        token: action.token,
        isAuthenticated: true,
      };
    case authActionTypes.COLLECT_EXISTING_CASSETTES:
      return {
        ...state,
        existingCassettes: action.existingCassettes,
      };
    case authActionTypes.COLLECT_NEW_CASSETTES:
      return {
        ...state,
        cassettes: action.cassettes,
        openings: action.openings,
      };
    default:
      return state;
  }
};

export default authReducer;
