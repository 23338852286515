import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PreviewComponent from '../../components/PreviewComponent/PreviewComponent';

import Spinner from '../../components/Spinner/Spinner';
import Facade from '../../facadeComponents/Facade/Facade';
import { beingEdited } from '../../redux/facade/facade.actions';
import { exitUnitView } from '../../redux/ui/ui.actions';

const FacadeConfig = (props) => {
  const [width, setWidth] = useState(); //used to determine parrent component size so the facade (child) scales accordingly
  const [height, setHeight] = useState(); //used to determine parrent component size so the facade (child) scales accordingly
  const building = useSelector((state) => state.building);
  const stylePackage = useSelector((state) => state.newFacade.stylePackage);
  const isLoadingOptions = useSelector((state) => state.ui.isLoadingOptions);
  const dispatch = useDispatch();

  //generating ids for facade elements
  const floors = [];
  const entrances = [];
  for (let i = 1; i <= building.floors; i++) {
    floors.push(i);
  }
  for (let i = 1; i <= building.entrances; i++) {
    entrances.push(i);
  }

  useEffect(() => {
    dispatch(beingEdited('front'));
  }, [dispatch]);

  //used to determine component size, then it will be forwarded to Facade component and compared with its size
  useEffect(() => {
    dispatch(exitUnitView());
    const previewDom = document.getElementsByClassName('preview');
    let timer2 = setTimeout(() => {
      if (previewDom[0] !== undefined) {
        setWidth(previewDom[0].offsetWidth);
        setHeight(previewDom[0].offsetHeight);
      }
    }, 100);

    return () => {
      clearTimeout(timer2);
    };
  }, [width, height, floors, entrances, dispatch]);

  // prop 'new' is used via props drilling to 'inform' Unit component to use New facade redux state for mapping new cassettes
  const FacadeConfigPreview = () =>
    //NewFacade "front"is === {} by default, meaning that style package has not been selected and that new facade has not been initiated
    stylePackage === '' ? (
      'Please choose style package!'
    ) : (
      <Facade
        entrances={entrances}
        floors={floors}
        new={true}
        inputWidth={width}
        inputHeight={height}
      />
    );

  return (
    <PreviewComponent>
      {isLoadingOptions ? (
        <div style={{ height: '200px', width: '200px' }}>
          <Spinner />
        </div>
      ) : (
        <FacadeConfigPreview />
      )}
    </PreviewComponent>
  );
};

export default FacadeConfig;
