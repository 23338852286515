import React from 'react';
import './CurbUnit.styles.scss';
import { useSelector } from 'react-redux';
import Cassette from '../Cassette/Cassette';

const CurbUnit = (props) => {
  const unitid = `${props.unit}${props.sectionId}`;
  const curbPanels = useSelector(
    (state) => state.facade[state.facade.beingEdited].curbPanels
  );
  const newProp = props.new;
  let dbPanels = useSelector((state) => state.auth.existingCassettes);
  let newDbPanels = useSelector((state) => state.auth.cassettes);

  if (newProp) {
    dbPanels = newDbPanels;
  }

  let Cassettes;
  if (curbPanels !== undefined && dbPanels !== undefined) {
    Cassettes = curbPanels.map((panel) => {
      const filteredDbPanel = dbPanels.filter((p) => p.name === panel.name);
      return (
        <div className="curb" key={panel.id}>
          <Cassette
            id={panel.id}
            svg={filteredDbPanel[0].svg}
            new={newProp}
            unitid={unitid}
          />
        </div>
      );
    });
  }

  return <div className="unit">{Cassettes}</div>;
};

export default CurbUnit;
