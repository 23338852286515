import { uiActionTypes } from './ui.types';

export const showModal = () => ({
  type: uiActionTypes.SHOW_MODAL,
});

export const hideModal = () => ({
  type: uiActionTypes.HIDE_MODAL,
});

export const showUnitView = (unit) => ({
  type: uiActionTypes.SHOW_UNIT_VIEW,
  unit: unit,
});
export const exitUnitView = () => ({
  type: uiActionTypes.EXIT_UNIT_VIEW,
});

export const setIsLoadingOptions = () => ({
  type: uiActionTypes.SET_IS_LOADING_OPTIONS,
});

export const setDaylightHoverValue = (value, visibility) => ({
  type: uiActionTypes.SET_DAYLIGHT_HOVER_VALUE,
  value: value,
  visibility: visibility,
});
