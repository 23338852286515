import { uiActionTypes } from './ui.types';

const initialState = {
  modalVisible: false,
  unitViewActive: false,
  isLoadingOptions: false,
  unitToPreviewed: '',
  daylightHoverValue: null,
  daylightHoverValueVisibility: false,
};

const setDaylightHoverValue = (state, action) => {
  let visibility = action.value.pass === 3 ? false : action.visibility;
  return {
    ...state,
    daylightHoverValue: action.value,
    daylightHoverValueVisibility: visibility,
  };
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case uiActionTypes.SHOW_MODAL:
      return { ...state, modalVisible: true };
    case uiActionTypes.HIDE_MODAL:
      return { ...state, modalVisible: false };
    case uiActionTypes.SHOW_UNIT_VIEW:
      return { ...state, unitViewActive: true, unitToPreviewed: action.unit };
    case uiActionTypes.EXIT_UNIT_VIEW:
      return { ...state, unitViewActive: false };
    case uiActionTypes.SET_IS_LOADING_OPTIONS:
      return { ...state, isLoadingOptions: !state.isLoadingOptions };
    case uiActionTypes.SET_DAYLIGHT_HOVER_VALUE:
      return setDaylightHoverValue(state, action);
    default:
      return state;
  }
};

export default uiReducer;
