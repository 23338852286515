import React from 'react';
import Colors from '../../constants/Colors';

const RearCourtyard = (props) => (
  <div {...props}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 150 150"
    >
      <g stroke={Colors.bluemid} strokeWidth="1">
        <rect
          className="cls-1"
          x="34.55"
          y="119.83"
          width="80.9"
          height="26.64"
          fill={Colors.fill}
        />
        <rect
          className="cls-2"
          x="47.1"
          y="137.56"
          width="11.45"
          height="8.92"
          fill={Colors.main}
        />
        <rect
          className="cls-2"
          x="89.77"
          y="137.56"
          width="11.45"
          height="8.92"
          fill={Colors.main}
        />
        <rect
          className="cls-3"
          x="92.71"
          y="61.68"
          width="80.9"
          height="26.64"
          transform="translate(58.16 208.16) rotate(-90)"
          fill="none"
        />
        <rect
          className="cls-3"
          x="34.55"
          y="3.52"
          width="80.9"
          height="26.64"
          transform="translate(150 33.69) rotate(-180)"
          fill="none"
        />
        <rect
          className="cls-3"
          x="-23.61"
          y="61.68"
          width="80.9"
          height="26.64"
          transform="translate(91.84 58.16) rotate(90)"
          fill="none"
        />
      </g>
    </svg>
  </div>
);

export default RearCourtyard;
