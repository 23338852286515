import React, { useEffect, useState } from 'react';
import './Unit.styles.scss';
import { useSelector, useDispatch } from 'react-redux';
import Panel from '../Panel/Panel';
import { showUnitView } from '../../redux/ui/ui.actions';

const Unit = (props) => {
  const [panels, setPanels] = useState([]);
  const unitid = parseInt(`${props.unit}${props.sectionId}`);
  const facade = useSelector((state) => state.facade);
  const newFacade = useSelector((state) => state.newFacade);
  const unitViewActive = useSelector((state) => state.ui.unitViewActive);
  const unitToDisplay = useSelector((state) => state.ui.unitToPreviewed);
  const dispatch = useDispatch();

  const existingFacadeToDisplay = facade[facade.beingEdited].units;
  const newFacadeToDisplay = newFacade[facade.beingEdited];
  const newProp = props.new;

  useEffect(() => {
    let panelsToDisplay = existingFacadeToDisplay.filter(
      (unit) => unit.id === unitid.toString()
    );
    if (panelsToDisplay[0] !== undefined) {
      setPanels(panelsToDisplay[0].panels);
    }
    if (newProp) {
      panelsToDisplay = newFacadeToDisplay.filter(
        (unit) => unit.id === unitid.toString()
      );
      if (panelsToDisplay[0] !== undefined) {
        setPanels(panelsToDisplay[0].panels);
      }
    }
  }, [
    existingFacadeToDisplay,
    newFacadeToDisplay,
    unitid,
    newProp,
    unitToDisplay,
  ]);

  const Cassettes = panels.map((panel) => {
    //check if updateNewPanel reducer updated panels with selected opening (example 900C)
    let openingOne = '0';
    let openingTwo = '0';
    //openings will be prop-drilled to cassette component
    if (panel.openingOne !== '0') {
      openingOne = panel.openingOne;
    }
    if (panel.openingTwo !== '0') {
      openingTwo = panel.openingTwo;
    }
    return (
      <Panel
        id={panel.id}
        key={panel.id}
        unitid={unitid}
        className="panel-cassette"
        cassette={panel.name}
        unitViewActive={unitViewActive}
        new={newProp}
        //props.new are being drilled so all components know when new facade is being rendered (instead of using redux)
        openingOne={openingOne}
        openingTwo={openingTwo}
        //openings will be prop-drilled to cassette component svg
      />
    );
  });

  let conditionalClassname = 'unit';
  if (props.new && !unitViewActive) {
    conditionalClassname = 'unit-interactive';
  }
  if (props.new && unitViewActive && unitToDisplay === unitid) {
    conditionalClassname = 'unit-interactive-absolute';
  }
  if (props.new && unitViewActive && unitToDisplay !== unitid) {
    conditionalClassname = 'unit-interactive-hidden';
  }

  const unitClickHandler = () => {
    if (props.new) {
      dispatch(showUnitView(unitid));
    }
  };

  return (
    <div className={conditionalClassname} onClick={unitClickHandler}>
      {Cassettes}
    </div>
  );
};

export default Unit;
