export const newFacadeActionTypes = {
  FETCH_DESIGN_OPTION: 'FETCH_DESIGN_OPTION',
  UPDATE_NEW_PANELS: 'UPDATE_NEW_PANELS',
  UPDATE_NEW_PANEL_WIDTH: 'UPDATE_NEW_PANEL_WIDTH', //used when editing project from existing geometry page, if existing panel width changes, this will change new as well
  RESET_FRONT_FACADE: 'RESET_FRONT_FACADE', //used to reset front facade when door position is being changed within existing facade
  EDIT_NEW_FACADE: 'EDIT_NEW_FACADE', // used ti populate state from  'edited' project
  RESET_NEW_FACADE: 'RESET_NEW_FACADE', //used to revert state to initial state
  GET_PANEL_DAYLIGHT: 'GET_PANEL_DAYLIGHT',
  RESET_DAYLIGHT_MARKERS: 'RESET_DAYLIGHT_MARKERS', //reset daylight markers when orientation or position are being changed
  NEWFACADE_FETCH_START: 'NEWFACADE_FETCH_START',
  NEWFACADE_FETCH_SUCCESS: 'NEWFACADE_FETCH_SUCCESS',
};
