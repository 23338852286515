import React from 'react';
import './Panel.styles.scss';
import Cassette from '../Cassette/Cassette';
import DaylightingResult from '../../components/DaylightingResult/DaylightingResult';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../redux/ui/ui.actions';
import { withRouter } from 'react-router-dom';

const Panel = (props) => {
  const { unitid } = props;
  const panelId = parseInt(`${unitid}${props.id}`); //props.id represents panel order within unit
  const dispatch = useDispatch();

  const existingCassettes = useSelector(
    (state) => state.auth.existingCassettes
  );
  const newCassettes = useSelector((state) => state.auth.cassettes);
  const newFacade = useSelector((state) => state.newFacade);
  const beingEdited = useSelector((state) => state.facade.beingEdited);

  let isMirrored = false;
  let lightingResults;
  if (props.new) {
    newFacade[beingEdited].map((item) => {
      const { id, panels } = item;
      //map units to find one with same unit ID
      if (id === unitid.toString()) {
        //map panels to find one with same unit ID
        const newFacadePanelById = panels.filter((i) => i.id === props.id);
        if (
          newFacadePanelById[0] !== undefined &&
          newFacadePanelById[0].isMirrored === true //map panels to find if db returned mirrored/shuffled panels
        ) {
          isMirrored = true;
        }

        //get lighting results
        if (newFacadePanelById[0] !== undefined) {
          lightingResults = newFacadePanelById[0].daylight;
        }
      }
      return true;
    });
  }

  //compare cassette forwarded from modal with existing svgs and render cassette
  let svg = '';
  let cassetteWidth = '';
  let translate1 = 0;
  let translate2 = 0;
  let cassetteName = '';
  if (existingCassettes !== undefined) {
    const cassette = existingCassettes.filter(
      (item) => item.name === props.cassette
    );
    if (cassette[0] !== undefined) {
      svg = cassette[0].svg;
      cassetteWidth = cassette[0].width;
    }
  }
  if (props.new && newCassettes !== undefined) {
    const cassette = newCassettes.filter(
      (item) => item.name === props.cassette
    );
    if (cassette[0] !== undefined) {
      svg = cassette[0].svg;
      cassetteWidth = cassette[0].width;
      translate1 = cassette[0].opening1Translate;
      translate2 = cassette[0].opening2Translate;
      cassetteName = cassette[0].name;
    }
  }

  const editHandler = () => {
    if (!props.unitViewActive) {
      return;
    }
    dispatch(showModal());
    props.history.push(
      `/facade/${props.id + '-' + cassetteWidth + '-' + unitid}`
    );
  };

  const hoverHandler = (text) => {
    console.log(text);
  };

  return (
    <div
      style={
        cassetteName[0] === 'S' || cassetteName[0] === 'E'
          ? { pointerEvents: 'none' }
          : null
      } //this inline style will be used to prevent user from interacting with entrance and stairway vertical
      className={
        props.unitViewActive && props.match.path === '/facade'
          ? 'panel-interactive'
          : 'panel'
      }
      // onClick={() =>
      //   panelClickHandler(props.id + '-' + cassetteWidth + '-' + unitid)
      // }
    >
      {/* used to display daylighting results */}

      {/* Daylighting results only visible on newfacade configurator */}
      {props.new && (
        <DaylightingResult
          lightingResults={lightingResults}
          hoverHandler={hoverHandler}
        />
      )}

      {svg !== undefined && (
        <Cassette
          svg={svg}
          new={props.new}
          editHandler={editHandler}
          openingOne={props.openingOne}
          openingTwo={props.openingTwo}
          width={cassetteWidth}
          translate1={translate1}
          translate2={translate2}
          panelId={panelId}
          isMirrored={isMirrored}
        />
        //props  drilling of openingOne and openingTwo from Unit component to cassette svg to be injected
      )}
      {/* <span className="panel-text">{panelId}</span> */}
    </div>
  );
};
export default withRouter(Panel);
