import React, { useState, useEffect } from 'react';
import './ExistingModalContent.styles.scss';
import Colors from '../../constants/Colors';
import Input from '../Input/Input';
import RadioButtons from '../RadioButtons/RadioButtons';
import DropdownIcon from '../../assets/images/dropdown.svg';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import Modal from '../Modal/Modal';

import { hideModal } from '../../redux/ui/ui.actions';
import { updatePanels } from '../../redux/facade/facade.actions';

import Cassette from '../../facadeComponents/Cassette/Cassette';
import { updateNewPanelWidth } from '../../redux/newFacade/newFacade.actions';

const ExistingModalContent = (props) => {
  const [itemChecked, setItemChecked] = useState('');
  const [isEntrance, setIsEntrance] = useState(false);
  const [panelSize, setPanelSize] = useState('None');
  const stairway = useSelector((state) => state.building.stairway);

  //forwarded by React router from SelectPanel component to point on which panels is being selected
  const panelId = props.match.params.id;
  const dispatch = useDispatch();

  const dbExistingCassettes = useSelector(
    (state) => state.auth.existingCassettes
  );

  //beingEdited is being used to determine whether option to select entrance for the panels will be available.
  //It should be optional only for front facade
  const beingEdited = useSelector((state) => state.facade.beingEdited);

  //switch is Entrance always to false if edited facade is not  'front'
  useEffect(() => {
    if (beingEdited === 'rear' || beingEdited === 'gable') setIsEntrance(false);
  }, [beingEdited]);

  const discardHandler = () => {
    setItemChecked('');
    dispatch(hideModal());
    props.history.goBack();
    setIsEntrance(false);
    setPanelSize('None');
  };

  const dispatchHandler = () => {
    //if nothing is selected within modal, prevent actions
    if (itemChecked === '') {
      return;
    }
    dispatch(updatePanels(panelId, itemChecked, isEntrance));
    dispatch(updateNewPanelWidth(panelId, itemChecked, isEntrance));
    discardHandler();
  };

  let existingCassettes = '';
  if (dbExistingCassettes) {
    //use selected size to filter panels, exclude entrance and curb panels
    let toggledByWidth = dbExistingCassettes
      .filter((item) => {
        if (
          item.name[0] === 'E' ||
          item.name[0] === 'Z' ||
          item.name[0] === 'S'
        ) {
          return false;
        }
        return true;
      })
      .filter((item) => item.width === parseInt(panelSize.slice(0, 4)));

    //for gable facade show only Gable panels (with no openings), name starts with G....
    if (beingEdited === 'gable') {
      toggledByWidth = dbExistingCassettes
        .filter((item) => {
          if (item.name === 'E2400' || item.name === 'E2700') {
            return false;
          }
          return true;
        })
        .filter(
          (item) =>
            item.name[0] === 'G' &&
            item.width === parseInt(panelSize.slice(0, 4))
        );
    }

    //if entrance is positioned on facade, front stairway
    if (isEntrance && stairway) {
      toggledByWidth = dbExistingCassettes.filter(
        (item) =>
          item.name[0] === 'S' && item.width === parseInt(panelSize.slice(0, 4))
      );
    }

    existingCassettes = toggledByWidth.map((item) => (
      <div
        key={item.name}
        className="cassette-container"
        onClick={() => setItemChecked(item.name)}
      >
        <div className="cassette">
          <Cassette
            svg={item.svg}
            fill={itemChecked === item.name ? '#82A2C8' : ''}
            stroke={Colors.bluemid}
          />
          <div
            className={
              itemChecked === item.name ? 'checkmark-active ' : 'checkmark'
            }
          >
            &#10003;
          </div>
        </div>
      </div>
    ));
  }

  //options for all cassette widths
  let Options = (
    <React.Fragment>
      <option default value="none" hidden>
        {panelSize}
      </option>
      <option value="2400 mm">2400 mm</option>
      <option value="2700 mm">2700 mm</option>
      <option value="3000 mm">3000 mm</option>
      <option value="3300 mm">3300 mm</option>
      <option value="3600 mm">3600 mm</option>
      <option value="3900 mm">3900 mm</option>
      <option value="4200 mm">4200 mm</option>
      <option value="4500 mm">4500 mm</option>
      <option value="4800 mm">4800 mm</option>
      <option value="5100 mm">5100 mm</option>
      <option value="5400 mm">5400 mm</option>
    </React.Fragment>
  );

  //options for entrance cassettes, they are always narrower
  if (isEntrance) {
    Options = (
      <React.Fragment>
        <option default value="none" hidden>
          {panelSize}
        </option>
        <option value="2400 mm">2400 mm</option>
        <option value="2700 mm">2700 mm</option>
      </React.Fragment>
    );
  }

  //function to select only 2400mm and 2700mm wide panels, if extrence selection is being checked
  const entranceCheckHandler = () => {
    setIsEntrance(true);
    if (panelSize !== '2400 mm' && panelSize !== '2700 mm') {
      setPanelSize('None');
    }
  };

  const isDisabled = itemChecked === '';

  return (
    //history forwarded so goBack() function could be called
    <Modal
      dispatchHandler={dispatchHandler}
      discardHandler={discardHandler}
      history={props.history}
      disabled={isDisabled}
    >
      <div className="existing-modal">
        <h4 className="panel-selector-label">Panel selector</h4>
        <div className="existing-modal-inputs">
          <Input
            inputtype="select"
            type="select"
            name="completion-date"
            label="Choose panel width: "
            //additionally rendered icon inside input field
            icon={
              <div className="dropdown-icon-container">
                <img
                  src={DropdownIcon}
                  alt="dropdown"
                  className="dropdown-icon"
                />
              </div>
            }
            value="Please choose panel width"
            onChange={(e) => {
              setPanelSize(e.target.value);
            }}
          >
            {Options}
          </Input>
          <RadioButtons
            checkedHandler={isEntrance}
            optOne="No"
            optTwo="Yes"
            setChecked={entranceCheckHandler}
            setUnchecked={() => setIsEntrance(false)}
            title="Is building entrance positioned here? "
            style={
              beingEdited !== 'front' && { opacity: 0.5, pointerEvents: 'none' }
            }
          />
        </div>
        <div className="facade-modal-section">
          {' '}
          <div
            className={
              panelSize === 'None'
                ? 'modal-container'
                : 'modal-container active'
            }
          >
            {existingCassettes}
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default withRouter(ExistingModalContent);
