import React from 'react';
import SVG from 'react-inlinesvg';
import Colors from '../../constants/Colors';
import EditIcon from '../../assets/images/edit-icon.svg';
import { useSelector } from 'react-redux';

const Cassette = (props) => {
  const stylePackage = useSelector((state) => state.newFacade.stylePackage);
  const openings = useSelector((state) => state.auth.openings);
  const newProps = props.new;

  let stroke = Colors.main;
  let fill = Colors.fill;
  let framefill = 'grey';
  let transparent = '#fff';
  let panelfill = Colors.fill;
  let image = '';
  let imageSecondary = '';
  let translate1 = 0;
  let translate2 = 0;

  if (props.stroke) {
    stroke = props.stroke;
  }
  if (props.fill) {
    fill = props.fill;
  }
  if (props.framefill) {
    framefill = props.framefill;
  }

  if (props.panelfill) {
    panelfill = props.panelfill;
  }
  if (props.transparent) {
    transparent = props.transparent;
  }
  if (props.image) {
    image = props.image;
  }
  if (props.imageSecondary) {
    imageSecondary = props.imageSecondary;
  }

  if (props.translate1) {
    translate1 = props.translate1;
  }

  if (props.translate2) {
    translate2 = props.translate2;
  }

  //determine scg styling per style package
  if (newProps) {
    if (stylePackage === 'Martin') {
      panelfill = 'url(#img1)';
      fill = 'url(#img2)';
      stroke = Colors.bluemid;
      framefill = Colors.aludark;
      image = require('../../assets/textures/Timber Batons superwood.jpg');
      imageSecondary = require('../../assets/textures/SW01-Type-A_Texture.png');
      if (props.panelId !== undefined && props.panelId.toString()[0] === '1') {
        fill = 'url(#img1)';
      }
      if (props.unitid && props.unitid[0] === '0') {
        fill = 'url(#img1)';
      }
    } else if (stylePackage === 'Anna') {
      panelfill = 'url(#img2)';
      fill = 'url(#img2)';
      stroke = Colors.bluemid;
      framefill = Colors.aludark;
      image = require('../../assets/textures/Zinc Standing Seam.jpg');
      imageSecondary = require('../../assets/textures/Zinc Standing Seam.jpg');
      if (props.panelId !== undefined && props.panelId.toString()[0] !== '1') {
        fill = 'url(#img1)';
        panelfill = 'url(#img1)';
      }
      if (props.unitid && props.unitid[0] !== '0') {
        fill = 'url(#img1)';
        panelfill = 'url(#img1)';
      }
    } else if (stylePackage === 'Ella') {
      panelfill = 'url(#img1)';
      fill = 'url(#img1)';
      stroke = Colors.aludark;
      framefill = Colors.aludark;
      image = require('../../assets/textures/Aluminum Corrugated Sheet_Corten.jpg');
      imageSecondary = require('../../assets/textures/Aluminum Corrugated Sheet_Corten rotated.jpg');
      if (props.panelId !== undefined && props.panelId.toString()[0] === '1') {
        fill = 'url(#img2)';
        panelfill = 'url(#img2)';
      }
      if (props.unitid && props.unitid[0] === '0') {
        fill = 'url(#img2)';
      }
    } else if (stylePackage === 'Mark') {
      panelfill = 'url(#img2)';
      fill = 'url(#img1)';
      stroke = Colors.aludark;
      framefill = Colors.aludark;
      image = require('../../assets/textures/Concrete.jpg');
      imageSecondary = require('../../assets/textures/Concrete Dark.jpg');
      if (props.panelId !== undefined && props.panelId.toString()[0] !== '1') {
        fill = 'url(#img1)';
        panelfill = 'url(#img2)';
      }
      if (props.unitid && props.unitid[0] !== '0') {
        fill = 'url(#img1)';
        panelfill = 'url(#img2)';
      }
    } else if (stylePackage === 'Lars') {
      panelfill = 'url(#img2)';
      fill = 'url(#img2)';
      stroke = Colors.bluemid;
      framefill = Colors.aludark;
      image = require('../../assets/textures/Lars_UpperCladding_1.jpg');
      imageSecondary = require('../../assets/textures/Wood Black 4.jpg');
      if (props.panelId !== undefined && props.panelId.toString()[0] !== '1') {
        fill = 'url(#img1)';
        panelfill = 'url(#img1)';
      }
      if (props.unitid && props.unitid[0] !== '0') {
        fill = 'url(#img1)';
        panelfill = 'url(#img1)';
      }
    } else {
      panelfill = Colors.fill;
      fill = Colors.fill;
      stroke = Colors.bluemid;
      framefill = Colors.aludark;
    }
  }

  //extracting svg of the opening 1 to be injected in cassette svg file
  let openingOne = '';
  if (openings !== undefined) {
    const openingOneSvg = openings.filter(
      (item) => item.name === props.openingOne
    );
    if (openingOneSvg[0] !== undefined) {
      openingOne = openingOneSvg[0].svg;
    }
  }

  //extracting svg of the opening 2 to be injected in cassette svg file
  let openingTwo = '';
  if (openings !== undefined) {
    const openingTwoSvg = openings.filter(
      (item) => item.name === props.openingTwo
    );
    if (openingTwoSvg[0] !== undefined) {
      openingTwo = openingTwoSvg[0].svg;
    }
  }

  //inject db svg string with react variables
  const injectProps = (string) => {
    let res = string.replaceAll('rb-openingOne', openingOne);
    res = res.replaceAll('rb-openingTwo', openingTwo);
    res = res.replace('rb-translate', translate1);
    res = res.replace('rb-translate', translate2);
    res = res.replaceAll('rb-width', `${props.width}`.slice(0, 3));
    res = res.replaceAll('rb-props.stroke', `"${stroke}"`);
    res = res.replaceAll('rb-props.fill', `"${fill}"`);
    res = res.replaceAll('rb-props.transparent', `"${transparent}"`);
    res = res.replaceAll('rb-framefill', `"${framefill}"`);
    res = res.replaceAll('rb-panelfill', `"${panelfill}"`);
    res = res.replaceAll('rb-image', `"${image}"`);
    res = res.replaceAll('rb-img-secondary', `"${imageSecondary}"`);
    return res;
  };

  return (
    <div className={props.isMirrored ? 'cassette-mirrored' : 'cassette'}>
      <SVG src={injectProps(props.svg)} />
      <div className="cassette-overlay">
        {/* rendered only on hover when unitvuew is active (only for new facade configuration)  */}
        <div className="cassette-overlay-icons" onClick={props.editHandler}>
          {/* editHandler is forwarded from Panel component and it opens modal, and sets route parameters to define panel type and position */}
          <img src={EditIcon} alt="edit" />
        </div>
      </div>
    </div>
  );
};
export default Cassette;
