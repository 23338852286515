import { projectActionTypes } from './project.types';

const initialState = {
  projectId: undefined,
  projectName: '',
  calculations: {
    price: '',
    acoustic: '',
    thermal: '',
    daylight: '',
    air: '',
    lca: '',
  },
  projects: [],
  isLoading: false,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectActionTypes.SAVE_PROJECT:
      return {
        ...state,
        projectName: action.name,
      };
    case projectActionTypes.GET_PROJECTS:
      return {
        ...state,
        projects: action.data,
      };
    case projectActionTypes.FETCH_CALCULATIONS:
      return {
        ...state,
        calculations: action.data,
      };
    case projectActionTypes.EDIT_PROJECT_ID:
      return {
        ...state,
        projectId: action.id,
        projectName: action.name,
      };
    case projectActionTypes.RESET_PROJECT:
      return { ...initialState };
    case projectActionTypes.PROJECT_FETCH_START:
      return { ...state, isLoading: true };
    case projectActionTypes.PROJECT_FETCH_SUCCESS:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default projectReducer;
