import React from 'react';
import './DaylightingResult.styles.scss';
import { setDaylightHoverValue } from '../../redux/ui/ui.actions';
import { useDispatch } from 'react-redux';

const DaylightingResult = (props) => {
  const { lightingResults } = props;
  const dispatch = useDispatch();

  let marker = '';
  if (lightingResults) {
    switch (lightingResults.pass) {
      case 0:
        marker = 'red';
        break;
      case 1:
        marker = 'yellow';
        break;
      case 2:
        marker = 'green';
        break;
      default:
        break;
    }
  }

  return (
    <div
      className="daylighting-result"
      style={{ backgroundColor: marker }}
      onMouseOver={() => dispatch(setDaylightHoverValue(lightingResults, true))}
      onMouseLeave={() =>
        dispatch(
          setDaylightHoverValue(
            { tenPercent: 0, DA300: 0, pass: 3, maxDepth: 0 },
            false
          )
        )
      }
    />
  );
};
export default DaylightingResult;
