import React, { useState, useEffect } from 'react';
import './NewModalContent.styles.scss';
import Colors from '../../constants/Colors';
import Modal from '../Modal/Modal';
import { hideModal } from '../../redux/ui/ui.actions';
import { updateNewPanels } from '../../redux/newFacade/newFacade.actions';
//import { getPanelDaylight } from '../../redux/project/project.actions';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import Cassette from '../../facadeComponents/Cassette/Cassette';
import PlusIcon from '../../iconComponents/PlusIcon/PlusIcon';

const NewModalContent = (props) => {
  const [itemChecked, setItemChecked] = useState(''); //sets which cassette is being checked (checked mark and dispatch)
  const [openingOneWidth, setOpeningOneWidth] = useState(0); //sets opening with which will be used for filtering and preview
  const [openingTwoWidth, setOpeningTwoWidth] = useState(0); //sets opening with which will be used for filtering and preview
  const [openingOne, setOpeningOne] = useState(''); //sets which opening is being checked (checked mark and dispatch)
  const [openingTwo, setOpeningTwo] = useState(''); //sets which opening is being checked (checked mark and dispatch)
  const [hasBalcony, setHasBalcony] = useState(false);
  const [hasSunscreen, setHasSunscreen] = useState(false);
  const [sectionToRender, setSectionToRender] = useState('section-1');
  //panelParam forwarded by React router from panel click handler of panel component to point on which panels is being selected
  //here it show panle order via integer, and panel width
  const panelParam = props.match.params.id;
  const dispatch = useDispatch();

  const dbNewCassettes = useSelector((state) => state.auth.cassettes);
  const dbOpenings = useSelector((state) => state.auth.openings);
  const newBuildingPanels = useSelector((state) => state.newFacade);
  const beingEdited = useSelector((state) => state.facade.beingEdited);
  const modalVisible = useSelector((state) => state.ui.modalVisible);

  let panelWidth = '';
  let panelId = '';
  //perhaps will be redundant if we do not use individual panel update
  let unitid = '';
  if (panelParam) {
    //exampe 1-2400-21, we use 1
    panelId = panelParam.split('-')[0];
    //exampe 1-2400-21, we use 2400
    panelWidth = panelParam.split('-')[1];
    //exampe 1-2400-21, we use 21, perhaps will be redundant if we do not use individual panel update
    unitid = panelParam.split('-')[2];
  }

  //since each panel with same order ID has same features, we can map through first one to validate in it has sunscreen or balcony
  useEffect(() => {
    const unitToMap = newBuildingPanels[beingEdited][0];
    if (newBuildingPanels[beingEdited][0]) {
      var panelToValidate = unitToMap.panels.find(
        (p) => p.id === parseInt(panelId)
      );

      if (panelToValidate && panelToValidate.hasBalcony && modalVisible) {
        setHasBalcony(true);
      }

      if (panelToValidate && panelToValidate.hasSunscreen && modalVisible) {
        setHasSunscreen(true);
      }
    }
  }, [modalVisible, beingEdited, newBuildingPanels, panelId]);

  const cassetteClickHandler = (name, openingOne, openingTwo) => {
    setItemChecked(name);
    setOpeningOneWidth(openingOne);
    setOpeningTwoWidth(openingTwo);
    if (openingTwo === 0) {
      setOpeningTwo('0');
    }

    if (name[0] === 'G') {
      setOpeningTwo('0');
      setOpeningOne('0');
    }
  };

  let newCassettes = '';
  if (dbNewCassettes) {
    let toggledByWidth = dbNewCassettes.filter(
      (item) =>
        item.width === parseInt(panelWidth) &&
        item.name[0] !== 'E' &&
        item.name[0] !== 'S' &&
        item.name[0] !== 'Z'
    );

    //if Mark or Ella design options are used, we have "semi-shuffled" panel variations.
    //We will only allow user to select main element while alterntiive , semishuffled element is assigned automatically from newFacade reducer ,
    //updateNewPanel function
    if (newBuildingPanels.stylePackage === 'Mark') {
      toggledByWidth = dbNewCassettes.filter(
        (item) =>
          item.width === parseInt(panelWidth) &&
          item.name !== '4800C' &&
          item.name !== '5100C' &&
          item.name[0] !== 'E' &&
          item.name[0] !== 'S' &&
          item.name[0] !== 'Z'
      );
    }
    if (newBuildingPanels.stylePackage === 'Ella') {
      toggledByWidth = dbNewCassettes.filter(
        (item) =>
          item.width === parseInt(panelWidth) &&
          item.name !== '4500C' &&
          item.name[0] !== 'E' &&
          item.name[0] !== 'S' &&
          item.name[0] !== 'Z'
      );
    }

    newCassettes = toggledByWidth.map((item) => (
      <div
        key={item.name}
        className="cassette-container"
        onClick={() =>
          cassetteClickHandler(
            item.name,
            item.opening1Width,
            item.opening2Width
          )
        }
      >
        <div className="cassette">
          <Cassette svg={item.svg} new />
          <div
            className={
              itemChecked === item.name ? 'checkmark-active ' : 'checkmark'
            }
          >
            &#10003;
          </div>
        </div>
      </div>
    ));
  }

  const discardHandler = () => {
    setItemChecked('');
    setOpeningOneWidth(0);
    setOpeningTwoWidth(0);
    setOpeningOne('');
    setOpeningTwo('');
    setSectionToRender('section-1');
    dispatch(hideModal());
    props.history.goBack();
    setHasBalcony(false);
    setHasSunscreen(false);
  };

  const dispatchHandler = () => {
    //if nothing is selected within modal, prevent actions
    if (itemChecked === '' || openingOne === '' || openingTwo === '') {
      return;
    }
    //reset state of this component
    //dispatch action to determine panel position, panel type, and openings to inject
    dispatch(
      updateNewPanels(
        panelId,
        unitid,
        itemChecked,
        openingOne,
        openingTwo,
        hasSunscreen,
        hasBalcony
      )
    );
    discardHandler();
  };

  let openingsOne = '';
  if (dbOpenings) {
    openingsOne = dbOpenings
      .filter(
        (opening) =>
          opening.width === openingOneWidth &&
          //exclude small 'bathroom' opening
          opening.name !== '900B'
      )
      .map((item) => (
        <div
          key={item.name}
          className="cassette-container"
          onClick={() => setOpeningOne(item.name)}
        >
          <div className="cassette">
            <Cassette
              svg={item.svg}
              width={openingOneWidth.toString().slice(0, -1)} //deduce number which is forwarded to svg, instead of 1800, formard 180 for svg viewbox
              translate={0}
              new
            />
            <div
              className={
                openingOne === item.name ? 'checkmark-active ' : 'checkmark'
              }
            >
              &#10003;
            </div>
          </div>
        </div>
      ));
  }

  let openingsTwo = '';
  if (dbOpenings) {
    openingsTwo = dbOpenings
      .filter(
        (opening) =>
          opening.width === openingTwoWidth &&
          //exclude small 'bathroom' opening
          opening.name !== '900B'
      )
      .map((item) => (
        <div
          key={item.name}
          className="cassette-container"
          onClick={() => setOpeningTwo(item.name)}
        >
          <div className="cassette">
            <Cassette
              svg={item.svg}
              width={openingTwoWidth.toString().slice(0, -1)} //deduce number which is forwarded to svg, instead of 1800, formard 180 for svg viewbox
              new
            />
            <div
              className={
                openingTwo === item.name ? 'checkmark-active ' : 'checkmark'
              }
            >
              &#10003;
            </div>
          </div>
        </div>
      ));
  }

  const isDisabled =
    itemChecked === '' || openingOne === '' || openingTwo === '' ? true : false;

  return (
    //history forwarded so goBack() function could be called
    <Modal
      dispatchHandler={dispatchHandler}
      discardHandler={discardHandler}
      history={props.history}
      disabled={isDisabled}
    >
      <div className="existing-modal">
        <h4 className="panel-selector-label">Panel selector</h4>
        <div
          className="selection-label"
          onClick={() => setSectionToRender('section-1')}
        >
          <PlusIcon fill={Colors.main} className="plusicon" />
          Panel {panelWidth}mm
        </div>
        <div
          className={
            sectionToRender === 'section-1'
              ? 'new-facade-modal-section'
              : 'new-facade-modal-section-collapsed'
          }
        >
          <div className="modal-container active">{newCassettes}</div>
        </div>

        <div
          style={
            openingOneWidth === '' || openingOneWidth === 0
              ? { opacity: '0.5', pointerEvents: 'none' }
              : null
          }
          className="selection-label"
          onClick={() => setSectionToRender('section-2')}
        >
          <PlusIcon fill={Colors.main} className="plusicon" />
          Opening 1
        </div>
        <div
          className={
            sectionToRender === 'section-2'
              ? 'new-facade-modal-section'
              : 'new-facade-modal-section-collapsed'
          }
        >
          {openingOneWidth !== '' && (
            <div className="modal-container active">{openingsOne} </div>
          )}
        </div>

        <div
          style={
            openingTwoWidth === '' || openingTwoWidth === 0
              ? { opacity: '0.5', pointerEvents: 'none' }
              : null
          }
          className="selection-label"
          onClick={() => setSectionToRender('section-3')}
        >
          <PlusIcon fill={Colors.main} className="plusicon" />
          Opening 2
        </div>
        <div
          className={
            sectionToRender === 'section-3'
              ? 'new-facade-modal-section'
              : 'new-facade-modal-section-collapsed'
          }
        >
          {openingTwoWidth !== '' && (
            <div className="modal-container active">{openingsTwo} </div>
          )}
        </div>
        <div
          className="selection-label"
          onClick={() => setHasSunscreen(!hasSunscreen)}
        >
          <PlusIcon fill={Colors.main} className="plusicon" />
          {hasSunscreen ? 'Remove Solar Shading' : 'Add Solar Shading'}
        </div>
        <div
          className="selection-label"
          onClick={() => setHasBalcony(!hasBalcony)}
        >
          <PlusIcon fill={Colors.main} className="plusicon" />
          {hasBalcony ? 'Remove Balcony' : 'Add Balcony'}
        </div>
      </div>
    </Modal>
  );
};

export default withRouter(NewModalContent);
