import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
  card: {
    marginHorizontal: 'auto',
    marginVertical: 30,
    backgroundColor: '#b3cce8',
    width: '70%',
    color: '#2E588A',
    border: '1pt solid #2E588A',
    borderBottom: 'none',
  },
  cardItem: {
    height: 20,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '18pt 120pt 18pt 18pt',
    borderBottom: '1pt solid #2E588A',
  },
  currency: {
    right: 110,
    top: 11,
    position: 'absolute',
  },
});

// Create Document Component
const PdfOverviewCard = (props) => {
  const calc = props.calc;
  const thermal = calc.thermal;

  const thermalValue =
    calc.thermal && calc.thermal > 40
      ? 'Significant'
      : thermal < 15
      ? 'Small'
      : 'Good';

  //thousand separator
  const numberWithCommas = (number) => {
    const x = (number / 1000).toFixed(1); //divided by 1000 to be displayed in Tons
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <View style={styles.card}>
      {/* conditional image render start*/}
      {props.image === 'Martin' && (
        <Image
          style={styles.cardImage}
          src={require('../assets/images/Martin.jpg')}
        />
      )}
      {props.image === 'Mark' && (
        <Image
          style={styles.cardImage}
          src={require('../assets/images/Mark.jpg')}
        />
      )}
      {props.image === 'Anna' && (
        <Image
          style={styles.cardImage}
          src={require('../assets/images/Anna.jpg')}
        />
      )}
      {props.image === 'Ella' && (
        <Image
          style={styles.cardImage}
          src={require('../assets/images/Ella.jpg')}
        />
      )}
      {props.image === 'Lars' && (
        <Image
          style={styles.cardImage}
          src={require('../assets/images/Lars.jpg')}
        />
      )}
      {/* conditional image render end*/}

      <View style={styles.cardItem}>
        <Text>Price :</Text>
        <Text render={() => (calc.price === '' ? '/' : calc.price)} />
        <Text style={styles.currency}>DKK</Text>
      </View>

      <View style={styles.cardItem}>
        <Text>Acoustic :</Text>
        <Text render={() => (calc.acoustic === '' ? '/' : calc.acoustic)} />
      </View>
      <View style={styles.cardItem}>
        <Text>Thermal :</Text>
        <Text
          render={() =>
            calc.thermal === '' ? '/' : `${thermalValue} - ${calc.thermal}`
          }
        />
        <Text style={styles.currency}>%</Text>
      </View>

      <View style={styles.cardItem}>
        <Text>Daylight :</Text>
        <Text
          render={() =>
            calc.daylight === ''
              ? '/'
              : `${calc.daylight.good} Good/${calc.daylight.medium} Medium/${calc.daylight.bad} Bad`
          }
        />
        <Text style={styles.currency}>rooms</Text>
      </View>

      <View style={styles.cardItem}>
        <Text>Air :</Text>
        <Text
          render={() =>
            calc.air === '' ? '/' : calc.air.constantPerPanel.toString()
          }
        />
        <Text style={styles.currency}>l/s per room</Text>
      </View>

      <View style={styles.cardItem}>
        <Text>LCA :</Text>
        <Text
          render={() =>
            calc.lca === '' ? '/' : numberWithCommas(calc.lca.maxSum).toString()
          }
        />
        <Text style={styles.currency}>t CO2 eq./building</Text>
      </View>
    </View>
  );
};
export default PdfOverviewCard;
