import React, { useEffect, useState } from 'react';
import Section from '../Section/Section';
import './Facade.styles.scss';
import { useSelector } from 'react-redux';

const Facade = (props) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [parentWidth, setParentWidth] = useState();
  const [parentHeight, setParentHeight] = useState();
  const [scale, setScale] = useState();

  const editedFacade = useSelector((state) => state.facade);
  const unitViewActive = useSelector((state) => state.ui.unitViewActive);
  const { floors, entrances } = props;
  const [facadeToMap, setFacadeToMap] = useState(entrances);

  const { inputWidth, inputHeight } = props;

  // used to set gable facade as single section, without entrances
  useEffect(() => {
    if (editedFacade.beingEdited === 'gable') {
      setFacadeToMap([1]);
      return;
    }
    setFacadeToMap(entrances);
  }, [editedFacade, entrances]);

  //collect component size if scroll, zoom or scale is needed
  useEffect(() => {
    const facadeDom = document.getElementsByClassName('facade');

    //save ref defined size of the component to state
    let timer3 = setTimeout(() => {
      if (facadeDom[0] !== undefined) {
        setWidth(facadeDom[0].offsetWidth);
        setHeight(facadeDom[0].offsetHeight);
      }
    }, 300);

    //save ref defined parrent size to state
    setParentWidth(inputWidth);
    setParentHeight(inputHeight);

    setScale(Math.min(parentWidth / width, parentHeight / height));

    return () => {
      clearTimeout(timer3);
    };
  }, [
    width,
    height,
    floors,
    entrances,
    parentWidth,
    parentHeight,
    scale,
    inputWidth,
    inputHeight,
    unitViewActive,
  ]);

  //used to inject conditional scale value to Facade component, calculated on base of parent component size, ant this component size
  let css;
  if ((width > parentWidth || height > parentHeight) && !unitViewActive) {
    const ctrans = 'scale(' + scale + ')';
    css = {
      transform: ctrans,
      transformOrigin: 'center',
      transition: 'all 0.3s',
    };
  }

  return (
    <div
      className={floors.length === 1 && entrances.length === 1 ? '' : 'facade'}
      style={css}
      id="fac"
    >
      {floors.length === 1 && entrances.length === 1 ? (
        <div>
          {' '}
          <div>Please choose building layout!</div>
          <div>
            Prior to panel selection, please choose number of floors, entrances
            and panels as well for all three facade sides
          </div>
        </div>
      ) : (
        facadeToMap.map((unit) => (
          //  prop 'new' is used via props drilling to 'inform' Unit component to use New facade redux state for mapping new cassettes
          <Section
            floors={floors}
            sectionId={unit}
            key={unit}
            new={props.new}
          />
        ))
      )}
    </div>
  );
};

export default Facade;
