import { authActionTypes } from './auth.types';
import jwt_decode from 'jwt-decode';
import { baseUrl } from '../../api/index';

export const login = (email, password) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${baseUrl}/auth`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });

      const resData = await response.json();
      const decodedToken = jwt_decode(resData.token);

      dispatch({
        type: authActionTypes.LOGIN,
        userName: decodedToken.name,
        userId: decodedToken._id,
        token: 'Bearer ' + resData.token,
      });

      saveDataToStorage(decodedToken.name, decodedToken._id, resData.token);
    } catch (error) {
      throw new Error('Something went wrong!');
    }
  };
};
const saveDataToStorage = (userName, userId, token) => {
  localStorage.setItem(
    'userData',
    JSON.stringify({
      userName: userName,
      userId: userId,
      token: 'Bearer ' + token,
    })
  );
};

export const logout = () => {
  localStorage.removeItem('userData');
  return { type: authActionTypes.LOGOUT };
};

export const authenticate = (userName, userId, token) => {
  return (dispatch) => {
    dispatch({
      type: authActionTypes.AUTHENTICATE,
      userName: userName,
      userId: userId,
      token: token,
    });
  };
};

//collect cassettes and opening (catalogs) from database
export const collectCassettes = () => {
  return async (dispatch, getState) => {
    const token = getState().auth.token;
    const response = await fetch(`${baseUrl}/cassettes`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (!response.ok) {
      const errorResData = await response.json();
      const errorId = errorResData.statusText;
      let message = 'Something went wrong!';
      if (errorId === 'EMAIL_EXISTS') {
        message = 'This email exists already!';
      }
      throw new Error(message);
    }
    const resData = await response.json();

    dispatch(saveCassettesToState(resData));
  };
};

//saving fetched cassettes and openings to Redux state
export const saveCassettesToState = (data) => ({
  type: authActionTypes.COLLECT_EXISTING_CASSETTES,
  existingCassettes: data.existing,
});

export const saveNewCassettesToState = (data) => ({
  type: authActionTypes.COLLECT_NEW_CASSETTES,
  cassettes: data.cassettes,
  openings: data.openings,
});

//validate user
export const checkUser = () => {
  return async (dispatch, getState) => {
    const token = await getState().auth.token;
    const userId = await getState().auth.userId;
    try {
      const response = await fetch(`${baseUrl}/users/me`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });

      const resData = await response.json();

      if (userId !== resData._id) {
        dispatch(logout());
      }
    } catch (err) {
      dispatch(logout());
    }
  };
};
