import { facadeActionTypes } from './facade.types';

export const selectPanelsNum = (text) => ({
  type: facadeActionTypes.SELECT_NUM,
  payload: text,
});

export const beingEdited = (text) => ({
  type: facadeActionTypes.BEING_EDITED,
  payload: text,
});

//this action is called asynchronously in initiate Facade call bellow
export const initiateFacadeWithGetState = (
  floors,
  entrances,
  panels,
  text
) => ({
  type: facadeActionTypes.INITIATE_FACADE,
  floors: floors,
  entrances: entrances,
  panels: panels,
  text: text,
});

//redux-thunk used in order to access getState so we can use state from other slices (building slice)
export const initiateFacade = () => {
  return (dispatch, getState) => {
    const floors = getState().building.floors;
    const entrances = getState().building.entrances;
    const panelsFront = getState().facade['front'].panels;
    const panelsRear = getState().facade['rear'].panels;
    const panelsGable = getState().facade['gable'].panels;

    dispatch(
      initiateFacadeWithGetState(floors, entrances, panelsFront, 'front')
    );
    dispatch(initiateFacadeWithGetState(floors, entrances, panelsRear, 'rear'));
    dispatch(initiateFacadeWithGetState(floors, 1, panelsGable, 'gable')); //because gable does not have any entrances, only single section is assumed
  };
};

export const updatePanels = (id, name, entrance = false) => ({
  type: facadeActionTypes.UPDATE_PANELS,
  id: id,
  name: name,
  entrance: entrance,
});

export const editFacade = (data) => ({
  type: facadeActionTypes.EDIT_FACADE,
  data: data,
});

export const resetFacade = () => ({
  type: facadeActionTypes.RESET_FACADE,
});

export const setPosition = (text) => ({
  type: facadeActionTypes.SET_POSITION,
  text: text,
});
