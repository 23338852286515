import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    marginTop: 20,
    width: '100%',
  },
  caption: {
    marginBottom: 10,
    fontSize: 22,
  },
  resultComment: {
    marginTop: 20,
    marginBottom: 40,
    width: '100%',
  },
});

//thousand separator
const numberWithCommas = (number) => {
  const x = (number / 1000).toFixed(1); //divided by 1000 to be displayed in Tons
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const PdfDescriptions = (props) => {
  const calc = props.calc;
  return (
    <View>
      <View style={styles.resultComment}>
        <Text style={styles.caption}>Daylighting results</Text>
        <Text
          render={() =>
            calc.daylight
              ? `${calc.daylight.good} velbelyste rum`
              : `0 velbelyste rum`
          }
        />
        <Text
          render={() =>
            calc.daylight
              ? `${calc.daylight.medium}  godkendte rum`
              : '0 godkendte rum'
          }
        />
        <Text
          render={() =>
            calc.daylight
              ? `${calc.daylight.bad} overholder ikke krav`
              : '0 overholder ikke krav'
          }
        />
        <Text style={styles.section}>
          Dagslyset er vurderet i henhold til Bygningsreglementets 300 lux
          regel, samt 10% reglen. I denne sammenhæng har vi valgt at sige at et
          rum anses for velbelyst hvis det overholder 300 lux reglen, men at det
          også er godkendt hvis det overholder 10% reglen. Rummene er simuleret
          for alle facadetyperne med en rumdybde på 5m, samt orienteringer i
          hhv. nord, syd, øst, vest. I forhold til de rum som ikke overholder
          kravene, kan dette være ok i disse tilfælde: Rummet er mindre dybt end
          de 5m, og kan potentielt set overholde kravet (vil kræve ny
          beregning). Rummet er et børneværelse/soveværelse der ikke har samme
          krav til dagslys. Dette vil kunne accepteres hvis boligen som helhed
          overholder kravet (vil kræve at man ser vægtet gennemsnit af 10%
          reglen på alle boligens opholdsrum). Kig på den tidligere side i
          konfiguratoren for at se detaljeret per rum, hvordan dagslysforholdene
          ser ud.
        </Text>
      </View>
      <View style={styles.resultComment}>
        <Text style={styles.caption}>Thermal results</Text>
        <Text>{`Improvement of the insulation capacity: ${calc.thermal} %`}</Text>
        <Text style={styles.section}>Percentage &lt;15% = Small</Text>
        <Text>Percentage between 15% and 40% = Good</Text>
        <Text>Percentage &gt;40% = Significant</Text>
      </View>
      <View style={styles.resultComment}>
        <Text style={styles.caption}>Life-cycle assesment results</Text>
        <Text>{`Life-cycle assesment impact per building: ${numberWithCommas(
          calc.lca.maxSum
        )} tones CO2 eq./building`}</Text>
      </View>
      <View style={styles.resultComment}>
        <Text style={styles.caption}>Air results</Text>
        <Text>10 l/s per rum med facadeadgang</Text>
      </View>
    </View>
  );
};
export default PdfDescriptions;
