import React from 'react';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import './ExistingConditions.styles.scss';
import Input from '../../components/Input/Input';
import RadioButtons from '../../components/RadioButtons/RadioButtons';
import DropdownIcon from '../../assets/images/dropdown.svg';
import CalendarIcon from '../../assets/images/calendar.svg';

import {
  selectPanelsNum,
  checkItem,
  uncheckItem,
  setYear,
  setOrientation,
  resetInsulation,
} from '../../redux/building/building.actions';
import { resetDaylightMarkers } from '../../redux/newFacade/newFacade.actions';

import { useSelector, useDispatch } from 'react-redux';

const ExistingConditions = (props) => {
  const building = useSelector((state) => state.building);
  const stylePackage = useSelector((state) => state.newFacade.stylePackage); //used when editin project to reset facade if stylePackage exists
  const dispatch = useDispatch();

  return (
    <div className="content-container column">
      <div className="captions">
        <h2>Existing building conditions</h2>
        <h4 className="heading-4">
          {building.buildingName ? building.buildingName : 'Building Name'}
        </h4>
      </div>
      <div className="pages-data-content">
        <Input
          inputtype="select"
          type="select"
          name="completion-date"
          label="Building year of completion: "
          icon={
            //additionally rendered icon inside input field
            <div className="dropdown-icon-container">
              <img
                src={CalendarIcon}
                alt="calendar"
                className="dropdown-icon"
              />
            </div>
          }
          onChange={(e) => dispatch(setYear(e.target.value))}
        >
          <option default value="Choose date" hidden>
            {building.year}
          </option>
          <option value="1970-1979">1961-1972</option>
          <option value="1980-1989">1973-1978</option>
          <option value="1990-1999">1979-1998</option>
        </Input>

        <RadioButtons
          checkedHandler={building.isRenovated}
          optOne="No"
          optTwo="Yes"
          setChecked={() => dispatch(checkItem('isRenovated'))}
          setUnchecked={() => dispatch(uncheckItem('isRenovated'))}
          title="Has the building been renovated?"
        />
        <RadioButtons
          checkedHandler={building.isInsulated}
          optOne="No"
          optTwo="Yes"
          setChecked={() => dispatch(checkItem('isInsulated'))}
          setUnchecked={() => {
            dispatch(uncheckItem('isInsulated'));
            dispatch(resetInsulation());
          }}
          title="Was insulation added to the walls?"
        />

        <Input
          value={building.insulationThickness}
          inputtype="select"
          type="select"
          name="insulation"
          label="Enter the insulation thickness 
         value (mm): "
          icon={
            //additionally rendered icon inside input field
            <div className="dropdown-icon-container">
              <img
                src={DropdownIcon}
                alt="dropdown"
                className="dropdown-icon"
              />
            </div>
          }
          onChange={(e) =>
            dispatch(
              selectPanelsNum('insulationThickness', parseInt(e.target.value))
            )
          }
          disabled={!building.isInsulated}
        >
          <option default value="none" hidden>
            {building.insulationThickness}
          </option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
        </Input>
        <RadioButtons
          checkedHandler={building.isAccessible}
          optOne="Low"
          optTwo="Adequate"
          setChecked={() => dispatch(checkItem('isAccessible'))}
          setUnchecked={() => dispatch(uncheckItem('isAccessible'))}
          title="What is the building accessibility 
        level?"
        />
        <RadioButtons
          checkedHandler={building.stairway} //two options so bool is used to determine choice
          optOne="Interior Stairway"
          optTwo="Front Stairway"
          setChecked={() => dispatch(checkItem('stairway'))}
          setUnchecked={() => dispatch(uncheckItem('stairway'))}
          title="Choose the type of stairway"
        />
        <Input
          inputtype="select"
          type="select"
          name="orientation"
          label="Choose entrance orientation: "
          icon={
            //additionally rendered icon inside input field
            <div className="dropdown-icon-container">
              <img
                src={DropdownIcon}
                alt="dropdown"
                className="dropdown-icon"
              />
            </div>
          }
          onChange={(e) => {
            dispatch(setOrientation(e.target.value));
            //used to reset newfacade, to remove daylight markers
            if (stylePackage !== '') {
              dispatch(resetDaylightMarkers());
            }
          }}
        >
          <option default value="none" hidden>
            {building.entranceOrientation}
          </option>
          <option value="East">East</option>
          <option value="West">West</option>
          <option value="North">North</option>
          <option value="South">South</option>
        </Input>
      </div>
      <NavigationButtons
        history={props.history}
        navigateto="/existing-building-geometry"
        disabled={building.entranceOrientation === 'None'} //entrance orientation must be selected in order to proceed
      />
    </div>
  );
};

export default ExistingConditions;
