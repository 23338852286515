import { newFacadeActionTypes } from './newFacade.types';

const initialState = {
  stylePackage: '',
  front: {},
  rear: {},
  gable: {},
  isLoading: false,
};

//creates initial state for facade layout after Fetching desing option from DB
//replaces existing panels with  'empty'  panels leaving only door positions on predefined locations
const fetchDesignOption = (state, action) => {
  const front = action.front;
  const rear = action.rear;
  const gable = action.gable;
  return {
    ...state,
    stylePackage: action.option,
    front: front.units,
    rear: rear.units,
    gable: gable.units,
  };
};

//Used for panel selection update
const updateNewPanel = (state, action) => {
  const editedItem = action.beingEdited;
  const units = state[editedItem];
  const stylePackage = state.stylePackage;

  const updatedPanel = (item) => {
    let name = action.name;

    //if Mark or Ella design options are used, we have "semi-shuffled" panel variations.
    //We will only allow user to select main element while alterntiive , semishuffled element is assigned automatically from newFacade reducer ,
    //updateNewPanel function
    if (stylePackage === 'Mark') {
      if (action.name === '4800B' && item.isSemiShuffled) {
        name = '4800C';
      }
      if (action.name === '5100B' && item.isSemiShuffled) {
        name = '5100C';
      }
    }
    if (stylePackage === 'Ella') {
      if (action.name === '4500D' && item.isSemiShuffled) {
        name = '4500C';
      }
    }

    return {
      id: item.id,
      name: name,
      isMirrored: item.isMirrored,
      width: action.name.slice(0, 4),
      openingOne: action.openingOne,
      openingTwo: action.openingTwo,
      hasBalcony: action.hasBalcony,
      hasSunscreen: action.hasSunscreen,
      daylight: { tenPercent: 0, DA300: 0, pass: 3, maxDepth: 0 },
      isSemiShuffled: item.isSemiShuffled,
    };
  };

  //WORKS FOR ALL PANELS WITH SAME ID (SAME VERTICAL ALIGNMENT)
  const mappedUnits = units.map((unit) => {
    const unitPanels = unit.panels.map((item) =>
      item.id.toString() === action.id && item.name[0] !== 'E'
        ? updatedPanel(item)
        : item
    );
    const updatedPanels = { ...unit, panels: unitPanels };
    return updatedPanels;
  });

  return {
    ...state,
    [editedItem]: mappedUnits,
  };
};

const updateNewPanelWidth = (state, action) => {
  let stateToReturn = state;
  if (state.stylePackage !== '' && state[action.beingEdited]) {
    const mappedUnits = state[action.beingEdited].map((item) => {
      //convert panel name if it starts with G... (if it is gable/full) panel
      let gablePanelName = action.name.slice(0, 4);
      if (action.name.slice(0, 1) === 'G' && action.name.slice(0, 1) === 'S') {
        gablePanelName = action.name.slice(1, 5);
      }

      let mappedPanels = item.panels.map((p) =>
        p.id === parseInt(action.id)
          ? {
              ...p,
              name: 'G' + gablePanelName,
              width: gablePanelName,
              daylight: { tenPercent: 0, DA300: 0, pass: 3, maxDepth: 0 },
            }
          : p
      );

      return { ...item, panels: mappedPanels };
    });
    stateToReturn = { ...state, [action.beingEdited]: mappedUnits };
  }
  return stateToReturn;
};

//used to reset front facade when door position is being changed within existing facade
const resetFrontFacade = (state, action) => {
  return { ...state, front: action.data.front.units };
};

//used to fetch individual daylighting results for each panel
const getPanelDaylight = (state, action) => {
  const editedItem = action.beingEdited;
  const units = state[editedItem];
  const lightingResults = action.data;

  //WORKS FOR ALL PANELS WITH SAME ID (SAME VERTICAL ALIGNMENT)
  const mappedUnits = units.map((unit) => {
    const unitPanels = unit.panels.map((item) =>
      item.id.toString() === action.id &&
      (item.name[0] !== 'E' || item.name[0] !== 'S' || item.name[0] !== 'G')
        ? { ...item, daylight: lightingResults }
        : item
    );
    const updatedPanels = { ...unit, panels: unitPanels };
    return updatedPanels;
  });

  return {
    ...state,
    [editedItem]: mappedUnits,
  };
};

const newFacadeReducer = (state = initialState, action) => {
  switch (action.type) {
    case newFacadeActionTypes.FETCH_DESIGN_OPTION:
      return fetchDesignOption(state, action);
    case newFacadeActionTypes.UPDATE_NEW_PANELS:
      return updateNewPanel(state, action);
    case newFacadeActionTypes.UPDATE_NEW_PANEL_WIDTH:
      return updateNewPanelWidth(state, action);
    case newFacadeActionTypes.EDIT_NEW_FACADE:
      return { ...action.data };
    case newFacadeActionTypes.RESET_FRONT_FACADE:
      return resetFrontFacade(state, action);
    case newFacadeActionTypes.GET_PANEL_DAYLIGHT:
      return getPanelDaylight(state, action);
    case newFacadeActionTypes.RESET_DAYLIGHT_MARKERS:
      return { ...action.newFacade };
    case newFacadeActionTypes.NEWFACADE_FETCH_START:
      return { ...state, isLoading: true };
    case newFacadeActionTypes.NEWFACADE_FETCH_SUCCESS:
      return { ...state, isLoading: false };
    case newFacadeActionTypes.RESET_NEW_FACADE:
      return { ...initialState };
    default:
      return state;
  }
};

export default newFacadeReducer;
