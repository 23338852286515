import React from 'react';
import './ExistingFacadeSelector.styles.scss';
import FacadeSelector from '../FacadeSelectorHOC/FacadeSelector';
import Incrament from '../Incrament/Incrament';

import Input from '../../components/Input/Input';
import DropdownIcon from '../../assets/images/dropdown.svg';
import SelectPanel from '../../components/SelectPanel/SelectPanel';
import PositionSelector from '../PositionSelector/PositionSelector';

import { useSelector, useDispatch } from 'react-redux';

//We use same incrament, decrament and select actions for all items, only use State slice item as param function call (floors, entrances, panels,insulationThickness... )
import { incrament, decrament } from '../../redux/building/building.actions';
import {
  selectPanelsNum,
  initiateFacade,
} from '../../redux/facade/facade.actions';
import { initiateDbNewFacade } from '../../redux/newFacade/newFacade.actions';

const ExistingFacadeSelector = (props) => {
  const building = useSelector((state) => state.building);
  const facades = useSelector((state) => state.facade);
  const loading = useSelector((state) => state.newFacade.isLoading); //used to pause next handler until api call gets resolved
  const stylePackage = useSelector((state) => state.newFacade.stylePackage);
  const editedFacade = facades.beingEdited;

  const dispatch = useDispatch();

  return (
    <FacadeSelector>
      <div className="top-facade-content">
        <Incrament
          title="Number of floors: "
          value={building.floors}
          incramentHandler={() => {
            dispatch(incrament('floors'));
            dispatch(initiateFacade());
            if (stylePackage) {
              dispatch(initiateDbNewFacade(stylePackage));
            }
          }}
          decramentHandler={() => {
            dispatch(decrament('floors'));
            dispatch(initiateFacade());
            if (stylePackage) {
              dispatch(initiateDbNewFacade(stylePackage));
            }
          }}
        />
        <Incrament
          title="Number of entrances: "
          value={building.entrances}
          incramentHandler={() => {
            dispatch(incrament('entrances'));
            dispatch(initiateFacade());
          }}
          decramentHandler={() => {
            dispatch(decrament('entrances'));
            dispatch(initiateFacade());
          }}
        />
        {loading ? (
          <div style={{ pointerEvents: 'none' }}>
            <PositionSelector editedFacade={editedFacade} />
          </div>
        ) : (
          <PositionSelector editedFacade={editedFacade} />
        )}
      </div>
      <div className="bottom-facade-content">
        <h4>Define exiting ground level unit</h4>
        <Input
          inputtype="select"
          type="select"
          name="completion-date"
          label="Number of panels: "
          //additionally rendered icon inside input field
          icon={
            <div className="dropdown-icon-container">
              <img
                src={DropdownIcon}
                alt="dropdown"
                className="dropdown-icon"
              />
            </div>
          }
          value={facades[editedFacade].panels}
          onChange={(e) => {
            dispatch(selectPanelsNum(parseInt(e.target.value)));
            dispatch(initiateFacade());
            if (stylePackage) {
              dispatch(initiateDbNewFacade(stylePackage));
            }
          }}
        >
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </Input>
        <SelectPanel />
      </div>
    </FacadeSelector>
  );
};

export default ExistingFacadeSelector;
