import React from 'react';
import Unit from '../Unit/Unit';
import './Section.styles.scss';
import CurbUnit from '../CurbUnit/CurbUnit';
import { useSelector } from 'react-redux';

const Section = (props) => {
  const { sectionId } = props;
  const unitViewActive = useSelector((state) => state.ui.unitViewActive);
  return (
    <div className="section">
      {/* THIS STEP IS DONE RETROACTIVELLY, THUS IT SEEMS THIS COULD BE DONE ALONG WITH OTHER PANELS */}
      {!unitViewActive && (
        <CurbUnit unit={0} sectionId={sectionId} key={0} new={props.new} />
      )}
      {props.floors.map((unit) => (
        //  prop 'new' is used via props drilling to 'inform' Unit component to use New facade redux state for mapping new cassettes
        <Unit unit={unit} sectionId={sectionId} key={unit} new={props.new} />
      ))}
    </div>
  );
};

export default Section;
