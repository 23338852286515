import React, { useState, useEffect } from 'react';
import './OverviewCard.styles.scss';
import EditIcon from '../../assets/images/edit-icon.svg';
import ShuffleIcon from '../../assets/images/shuffle-icon.svg';
import DeleteIcon from '../../assets/images/delete-icon.svg';

const OverviewCard = (props) => {
  const { calculations, hover } = props;
  const [thermal, setThermal] = useState('/');
  const [daylight, setDaylight] = useState();
  const [air, setAir] = useState('/');
  const [lca, setLca] = useState('/');
  const [forDisplay, setForDisplay] = useState('');

  useEffect(() => {
    if (calculations) {
      setThermal(calculations.thermal);
      setDaylight(calculations.daylight);
      setAir(calculations.air.constantPerPanel);
      setLca(calculations.lca);
    }
  }, [calculations]);

  //thousand separator
  const numberWithCommas = (number) => {
    const x = (number / 1000).toFixed(1); //divided by 1000 to be displayed in Tons
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  //conditional classname to show pointer only for this Overview card component, not on other pages
  const conditionalClassName = hover ? 'hover' : '';

  const HoverCard = () => {
    if (hover === true && thermal !== '') {
      if (forDisplay === 'daylight') {
        return (
          <div className="hover-card">
            <div>
              {daylight.good
                ? `${daylight.good} velbelyste rum`
                : `0 velbelyste rum`}
            </div>
            <div>
              {daylight.medium
                ? `${daylight.medium} godkendte rum`
                : `0 godkendte rum`}
            </div>
            <div className="newline">
              {daylight.bad
                ? `${daylight.bad} overholder ikke krav`
                : `0 overholder ikke krav`}
            </div>
            Dagslyset er vurderet i henhold til Bygningsreglementets 300 lux
            regel, samt 10% reglen. I denne sammenhæng har vi valgt at sige at
            et rum anses for velbelyst hvis det overholder 300 lux reglen, men
            at det også er godkendt hvis det overholder 10% reglen. Rummene er
            simuleret for alle facadetyperne med en rumdybde på 5m, samt
            orienteringer i hhv. nord, syd, øst, vest. I forhold til de rum som
            ikke overholder kravene, kan dette være ok i disse tilfælde: Rummet
            er mindre dybt end de 5m, og kan potentielt set overholde kravet
            (vil kræve ny beregning). Rummet er et børneværelse/soveværelse der
            ikke har samme krav til dagslys. Dette vil kunne accepteres hvis
            boligen som helhed overholder kravet (vil kræve at man ser vægtet
            gennemsnit af 10% reglen på alle boligens opholdsrum). Kig på den
            tidligere side i konfiguratoren for at se detaljeret per rum,
            hvordan dagslysforholdene ser ud.
          </div>
        );
      }
      if (forDisplay === 'air') {
        return (
          <div className="hover-card">10 l/s per rum med facadeadgang</div>
        );
      }
      if (forDisplay === 'thermal') {
        return (
          <div className="hover-card">
            <div className="newline">{`Improvement of the insulation capacity: ${thermal} %`}</div>
            <div>Percentage &lt;15% = Small</div>
            <div>Percentage between 15% and 40% = Good</div>
            <div>Percentage &gt;40% = Significant</div>
          </div>
        );
      }
      if (forDisplay === 'lca') {
        return (
          <div className="hover-card">
            <div>Life-cycle assesment impact per building: </div>

            <div className="newline">
              {`${numberWithCommas(lca.maxSum)} `}t CO
              <sub>2</sub> eq./building
            </div>
            <div>Life-cycle assesment impact per square meter of facade: </div>

            <div>
              {`${numberWithCommas(lca.maxSumPerSqm)} `} t CO
              <sub>2</sub> eq./m<sup>2</sup>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const thermalValue =
    thermal > 40 ? 'Significant' : thermal < 15 ? 'Small' : 'Good';

  return (
    <div className="overview-card">
      <HoverCard />
      {props.image && (
        <div className="overview-card-image">
          <img
            src={require(`../../assets/images/${props.image}.jpg`)}
            alt={`${props.image}`}
          />
          <div className="overview-card-overlay" />
          <div className="overview-card-icons">
            {/* editHandler is forwarded from Panel component and it opens modal, and sets route parameters to define panel type and position */}
            <img src={EditIcon} alt="edit" onClick={props.editHandler} />
            <img src={ShuffleIcon} alt="copy" onClick={props.copyHandler} />
            <img src={DeleteIcon} alt="delete" onClick={props.deleteHandler} />
          </div>
        </div>
      )}
      <div className="overview-card-item">
        Price:
        <span
          className={conditionalClassName}
          onMouseEnter={() => setForDisplay('price')}
          onMouseLeave={() => setForDisplay('')}
        >
          {calculations ? calculations.price : '/'}
          <span className="currency">DKK</span>
        </span>
      </div>
      <div className="overview-card-item">
        Acoustic:{' '}
        <span
          className={conditionalClassName}
          onMouseOver={() => setForDisplay('acoustic')}
          onMouseLeave={() => setForDisplay('')}
        >
          {calculations ? calculations.acoustic : '/'}
        </span>
      </div>
      <div className="overview-card-item">
        Thermal:{' '}
        <span
          className={conditionalClassName}
          onMouseOver={() => setForDisplay('thermal')}
          onMouseLeave={() => setForDisplay('')}
        >
          {calculations && thermal !== '' ? thermalValue : '/'}
          <span className="currency"></span>
        </span>
      </div>
      <div className="overview-card-item">
        Daylight:{' '}
        <span
          className={conditionalClassName}
          onMouseOver={() => setForDisplay('daylight')}
          onMouseLeave={() => setForDisplay('')}
        >
          {daylight !== undefined && daylight.medium !== undefined
            ? `${daylight.good}/${daylight.medium}/${daylight.bad}`
            : '/'}
        </span>
      </div>
      <div className="overview-card-item">
        Air:{' '}
        <span
          className={conditionalClassName}
          onMouseOver={() => setForDisplay('air')}
          onMouseLeave={() => setForDisplay('')}
        >
          {calculations && air !== undefined ? `${air}` : '/'}
          <span className="currency">l/s per room</span>
        </span>
      </div>
      <div className="overview-card-item">
        LCA:{' '}
        <span
          className={conditionalClassName}
          onMouseOver={() => setForDisplay('lca')}
          onMouseLeave={() => setForDisplay('')}
        >
          {calculations && lca !== '' ? `${numberWithCommas(lca.maxSum)}` : '/'}
          <span className="currency">
            t CO<sub>2</sub> eq./ building
          </span>
        </span>
      </div>
    </div>
  );
};
export default OverviewCard;
