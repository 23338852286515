import { facadeActionTypes } from './facade.types';

const initialState = {
  beingEdited: 'front',
  front: {
    panels: 3,
    units: [
      {
        id: '11',
        panels: [
          {
            id: 1,
            name: '',
            isMirrored: false,
            width: '',
            openingOne: '0',
            openingTwo: '0',
          },
          {
            id: 2,
            name: '',
            isMirrored: false,
            width: '',
            openingOne: '0',
            openingTwo: '0',
          },
          {
            id: 3,
            name: '',
            isMirrored: false,
            width: '',
            openingOne: '0',
            openingTwo: '0',
          },
        ],
      },
    ],
    curbPanels: [
      {
        id: 1,
        name: 'Z2400',
        width: '2400',
      },
      {
        id: 2,
        name: 'Z2400',
        width: '2400',
      },
      {
        id: 3,
        name: 'Z2400',
        width: '2400',
      },
    ],
    position: '',
  },
  rear: {
    panels: 3,
    units: [
      {
        id: '11',
        panels: [
          {
            id: 1,
            name: '',
            isMirrored: false,
            width: '',
            openingOne: '0',
            openingTwo: '0',
          },
          {
            id: 2,
            name: '',
            isMirrored: false,
            width: '',
            openingOne: '0',
            openingTwo: '0',
          },
          {
            id: 3,
            name: '',
            isMirrored: false,
            width: '',
            openingOne: '0',
            openingTwo: '0',
          },
        ],
        curbPanels: [
          {
            id: 1,
            name: 'Z2400',
            width: '2400',
          },
          {
            id: 2,
            name: 'Z2400',
            width: '2400',
          },
          {
            id: 3,
            name: 'Z2400',
            width: '2400',
          },
        ],
      },
    ],
    position: '',
  },
  gable: {
    panels: 3,
    units: [
      {
        id: '11',
        panels: [
          {
            id: 1,
            name: '',
            isMirrored: false,
            width: '',
            openingOne: '0',
            openingTwo: '0',
          },
          {
            id: 2,
            name: '',
            isMirrored: false,
            width: '',
            openingOne: '0',
            openingTwo: '0',
          },
          {
            id: 3,
            name: '',
            isMirrored: false,
            width: '',
            openingOne: '0',
            openingTwo: '0',
          },
        ],
      },
    ],
    curbPanels: [
      {
        id: 1,
        name: 'Z2400',
        width: '2400',
      },
      {
        id: 2,
        name: 'Z2400',
        width: '2400',
      },
      {
        id: 3,
        name: 'Z2400',
        width: '2400',
      },
    ],
  },
};

//trigers panel number change for particular facade
const selectPanelsNum = (state, action) => {
  const editedItem = state.beingEdited;
  return {
    ...state,
    [editedItem]: { ...state[editedItem], panels: action.payload },
  };
};

//creates initial state for facade layout
const initiateFacade = (state, action) => {
  const floors = new Array(action.floors);
  const entrances = new Array(action.entrances);
  const pan = new Array(action.panels);
  const facadeSide = action.text;

  const panels = [];
  for (let i = 1; i <= pan.length; i++) {
    panels.push({
      id: i,
      name: 'G2400',
      isMirrored: false,
      width: '',
      openingOne: '0',
      openingTwo: '0',
    });
  }

  const curbPanels = [];
  for (let i = 1; i <= pan.length; i++) {
    curbPanels.push({
      id: i,
      name: 'Z2400',
      width: '',
    });
  }

  const cassettes = [];
  for (let i = 1; i <= floors.length; i++) {
    for (let l = 1; l <= entrances.length; l++) {
      cassettes.push(i.toString() + l.toString());
    }
  }

  const newUnits = [];
  for (let i = 0; i <= cassettes.length - 1; i++) {
    newUnits.push({
      id: cassettes[i],
      panels: panels,
    });
  }
  return {
    ...state,
    [action.text]: {
      panels: state[action.text].panels,
      units: newUnits,
      curbPanels: curbPanels,
      position: state[facadeSide].position,
    },
  };
};

//Used for initial panels setup of existing geometry
const updatePanels = (state, action) => {
  const editedItem = state.beingEdited;
  const units = state[editedItem].units;

  //convert panel name if it starts with G... (if it is gable/full) panel
  let gablePanelName = action.name.slice(0, 4);
  if (action.name.slice(0, 1) === 'G' || action.name.slice(0, 1) === 'S') {
    gablePanelName = action.name.slice(1, 5);
  }

  const updatedPanel = {
    id: parseInt(action.id),
    name: action.name,
    isMirrored: false,
    width: gablePanelName,
    openingOne: '0',
    openingTwo: '0',
  };

  const entrancePanel = {
    id: parseInt(action.id),
    name: 'E' + gablePanelName + 'T',
    isMirrored: false,
    width: gablePanelName,
    openingOne: '0',
    openingTwo: '0',
  };

  //Store Panel which will replace entrance panel,'remember' upper panel above entrance
  const upperUnitToToggleEntrance = units.filter((item) => item.id === '21');

  const mappedUnits = units.map((item) => {
    //set selected panel to all panels with same ID vertically
    let unitPanels = item.panels.map((p) =>
      p.id === parseInt(action.id) ? updatedPanel : p
    );

    //if panel vertical is selected as "entrance"  position within existing geometry modal component
    if (action.entrance === true) {
      //replace S... panels on upper floors if already exist
      unitPanels = unitPanels.map((p) => {
        p =
          p.name[0] === 'S' && p.id !== parseInt(action.id)
            ? {
                id: p.id,
                name: 'G' + p.width,
                isMirrored: false,
                width: p.width,
                openingOne: '0',
                openingTwo: '0',
              }
            : p;
        return p;
      });

      unitPanels = unitPanels.map((p) => {
        //determines if any of panels has already been set as entrance
        let alreadyEntranceId = '';
        if (p.name[0] === 'E') {
          alreadyEntranceId = p.id;
        }

        //determines which of the panels will be used to replace entrance panel, unit has been selected above in upperUnitToToggleEntrance variable
        let panelToToggleEntrance = '';
        if (upperUnitToToggleEntrance[0] !== undefined) {
          panelToToggleEntrance = upperUnitToToggleEntrance[0].panels.filter(
            (i) => i.id === alreadyEntranceId
          );
        }
        const panelToReplace =
          p.name[0] === 'E'
            ? { ...panelToToggleEntrance[0], name: 'G' + p.width }
            : p;
        return panelToReplace;
      });

      //used if ground floor (id==='1'), if entrance checked  to position entrance panel
      unitPanels = unitPanels.map((p) =>
        item.id[0] === '1' && p.id.toString() === action.id ? entrancePanel : p
      );
    }

    const updatedPanels = { ...item, panels: unitPanels };
    return updatedPanels;
  });

  const mappedCurbPanels = state[editedItem].curbPanels.map((i) => {
    let curbPanel = 'Z' + gablePanelName;

    if (action.entrance === true) {
      curbPanel = 'E' + gablePanelName + 'B';
    }

    return i.id.toString() === action.id
      ? {
          id: parseInt(action.id),
          name: curbPanel,
          width: gablePanelName,
        }
      : i.name[0] === 'E' && action.entrance === true
      ? {
          id: i.id,
          name: 'Z' + i.width,
          width: i.width,
        }
      : i;
  });

  return {
    ...state,
    [editedItem]: {
      ...state[editedItem],
      panels: state[editedItem].panels,
      units: mappedUnits,
      curbPanels: mappedCurbPanels,
    },
  };
};

const setPosition = (state, action) => {
  const editedItem = state.beingEdited;
  return {
    ...state,
    [editedItem]: {
      ...state[editedItem],
      position: action.text,
    },
  };
};

const facadeReducer = (state = initialState, action) => {
  switch (action.type) {
    case facadeActionTypes.BEING_EDITED:
      return { ...state, beingEdited: action.payload };
    case facadeActionTypes.SELECT_NUM:
      return selectPanelsNum(state, action);
    case facadeActionTypes.INITIATE_FACADE:
      return initiateFacade(state, action);
    case facadeActionTypes.UPDATE_PANELS:
      return updatePanels(state, action);
    case facadeActionTypes.EDIT_FACADE:
      return { ...action.data };
    case facadeActionTypes.SET_POSITION:
      return setPosition(state, action);
    case facadeActionTypes.RESET_FACADE:
      return { ...initialState };
    default:
      return state;
  }
};

export default facadeReducer;
