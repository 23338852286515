import React from 'react';
import {
  Page,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';

import PdfOverviewCard from './PdfOverviewCard';
import PdfBuildingData from './PdfBuildingData';
import PdfDescriptions from './PdfDescriptions';

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    padding: 30,
    fontSize: 12,
  },
  header: {
    marginBottom: 30,
  },
  image: {
    height: 30,
    width: 100,
  },
});

// Create Document Component
const PdfDocument = (props) => {
  const state = props.state;
  const calc = state.project.calculations;

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View style={styles.header} fixed>
          <Image
            style={styles.image}
            src={require('../assets/images/rebus-logo.png')}
          />
        </View>
        <PdfOverviewCard calc={calc} image={props.image} />
        <PdfBuildingData state={state} />
      </Page>
      <Page size="A4" style={styles.page} wrap>
        <PdfDescriptions calc={calc} />
      </Page>
    </Document>
  );
};

Font.register({
  family: 'Roboto',
  src: 'http://fonts.gstatic.com/s/roboto/v15/W5F8_SL0XFawnjxHGsZjJA.ttf',
});

export default PdfDocument;
