export const projectActionTypes = {
  SAVE_PROJECT: 'SAVE_PROJECT',
  GET_PROJECTS: 'GET_PROJECTS',
  DELETE_PROJECT: 'DELETE_PROJECT',
  COPY_PROJECT: 'COPY_PROJECT',
  EDIT_PROJECT: 'EDIT_PROJECT',
  EDIT_PROJECT_ID: 'EDIT_PROJECT_ID',
  FETCH_CALCULATIONS: 'FETCH_CALCULATIONS',
  RESET_PROJECT: 'RESET_PROJECT',
  PROJECT_FETCH_START: 'PROJECT_FETCH_START',
  PROJECT_FETCH_SUCCESS: 'PROJECT_FETCH_SUCCESS',
};
