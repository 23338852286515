import React, { useState, useEffect } from 'react';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import FacadeSelector from '../../components//FacadeSelectorHOC/FacadeSelector';
import NewFacadeConfig from '../../components/NewFacadeConfig/NewFacadeConfig';
import { useSelector } from 'react-redux';
import OverviewCard from '../../components/OverviewCard/OverviewCard';

const Overview = (props) => {
  const building = useSelector((state) => state.building);
  const dbCalculations = useSelector((state) => state.project.calculations);
  const [calculations, setCalculations] = useState('');
  const loading = useSelector((state) => state.project.isLoading); //used to pause next handler until api call gets resolved

  useEffect(() => {
    if (dbCalculations) {
      setCalculations(dbCalculations);
    }
  }, [dbCalculations]);

  return (
    <div className="content-container column">
      <div className="captions">
        <h2>
          Scenario Overview |{' '}
          {building.buildingName ? building.buildingName : ' Building Name'}
        </h2>
        <h4 className="heading-4">Price and performance panel</h4>
      </div>

      <div className="row">
        <div className="left">
          <FacadeSelector>
            <div className="top-facade-content" style={{ marginTop: '130px' }}>
              <OverviewCard calculations={calculations} />
            </div>
          </FacadeSelector>
        </div>
        <div className="right" style={{ pointerEvents: 'none' }}>
          <NewFacadeConfig />
        </div>
      </div>

      <NavigationButtons
        history={props.history}
        navigateto="/checkout"
        disabled={loading}
      />
    </div>
  );
};

export default Overview;
