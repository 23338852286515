import React, { useEffect, useState } from 'react';
import './ExistingGeometry.styles.scss';
import NavigationButtons from '../../components/NavigationButtons/NavigationButtons';
import ExistingFacadeSelector from '../../components/ExistingFacadeSelector/ExistingFacadeSelector';
import { useSelector, useDispatch } from 'react-redux';

import PreviewComponent from '../../components/PreviewComponent/PreviewComponent';
import ExistingModalContent from '../../components/ExistingModalContent/ExistingModalContent';
import Facade from '../../facadeComponents/Facade/Facade';

import { collectCassettes } from '../../redux/auth/auth.actions';
import { beingEdited } from '../../redux/facade/facade.actions';

const ExistingGeometry = (props) => {
  const [width, setWidth] = useState(); //used to determine parrent component size so the facade (child) scales accordingly
  const [height, setHeight] = useState(); //used to determine parrent component size so the facade (child) scales accordingly
  const building = useSelector((state) => state.building);
  const facade = useSelector((state) => state.facade); //used only to extract const panels
  const panels = facade[facade.beingEdited].panels; //extracted only to be used as argument of useEffect hook so it triggers facade rerender upon size change
  const dispatch = useDispatch();

  //generating ids for facade elements
  const floors = [];
  const entrances = [];
  for (let i = 1; i <= building.floors; i++) {
    floors.push(i);
  }
  for (let i = 1; i <= building.entrances; i++) {
    entrances.push(i);
  }

  //Sends and API call to collect catalog of cassettes and openings from database
  useEffect(() => {
    dispatch(collectCassettes());
    dispatch(beingEdited('front'));
  }, [dispatch]);

  //used to determine component size, then it will be forwarded to Facade component and compared with its size
  useEffect(() => {
    const elements = document.getElementsByClassName('preview');
    let timer1 = setTimeout(() => {
      setWidth(elements[0].offsetWidth);
      setHeight(elements[0].offsetHeight);
    }, 100);

    return () => {
      clearTimeout(timer1);
    };
  }, [width, height, floors, entrances, panels]);

  return (
    <div className="content-container column">
      <div className="captions">
        <h2>Define existing building geometry</h2>
        <h4 className="heading-4">
          {building.buildingName ? building.buildingName : 'Building Name'}
        </h4>
      </div>
      <div className="row">
        <div className="left">
          <ExistingFacadeSelector />
        </div>
        <div className="right">
          <PreviewComponent>
            <Facade
              entrances={entrances}
              floors={floors}
              inputWidth={width}
              inputHeight={height}
            />
          </PreviewComponent>
        </div>
      </div>

      <NavigationButtons
        history={props.history}
        navigateto="/facade"
        disabled={!facade.front.position || !facade.rear.position} //neighbourhood position for both front and rear needs to be selected in order to proceed
      />
      <ExistingModalContent />
    </div>
  );
};

export default ExistingGeometry;
