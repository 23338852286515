import React from 'react';
import './NewFacadeSelector.styles.scss';
import FacadeSelector from '../FacadeSelectorHOC/FacadeSelector';
import { useDispatch, useSelector } from 'react-redux';
import { initiateDbNewFacade } from '../../redux/newFacade/newFacade.actions';

const ExistingFacadeSelector = (props) => {
  const stylePackage = useSelector((state) => state.newFacade.stylePackage);
  const dispatch = useDispatch();

  const designOption = (name) => (
    <div
      className="design-option"
      onClick={() => {
        dispatch(initiateDbNewFacade(name));
      }}
    >
      <img src={require(`../../assets/images/${name}.jpg`)} alt={`${name}`} />
      <div className={stylePackage === name ? 'design-option-overlay' : ''} />
      <div
        className={stylePackage === name ? 'checkmark-active ' : 'checkmark'}
      >
        &#10003;
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <FacadeSelector new={true}>
        <div className="top-facade-content">
          {/* <h4>
            Choose style package
            <span className="style-label">{stylePackage.toUpperCase()}</span>
          </h4> */}
          {designOption('Martin')}
          {designOption('Anna')}
          {designOption('Ella')}
          {designOption('Mark')}
          {designOption('Lars')}
        </div>
      </FacadeSelector>
    </React.Fragment>
  );
};

export default ExistingFacadeSelector;
